import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1',
      calf: '#808080',
      openHeifer: '#8381ff',
      insemHeifer: '#90ff00',
      uncalved: '#57ffff',
      involution: '#f9b9a1',
      open: '#7e0cff',
      insem: '#25803d',
      incalf: '#3ba7ff',
      barren: '#f5009d',
      dryoff: '#a28200',
      cull: '#f9bf00'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function StatusesChart(props) {
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
        chart: {
            height: 230,
            width: 190,
            type: 'donut'
          },
          labels: ['Теленок', 'Телка (открыта)', 'Осемененная телка', 'Нетель', 'Инволюция (рано)', 'Открыта (готова)', 'Осеменена', 'Стельная', 'Яловая', 'Сухостой', 'Выбраковка'],
          colors: [config.colors.calf, config.colors.openHeifer, config.colors.insemHeifer, config.colors.uncalved, config.colors.involution, config.colors.open, config.colors.insem, config.colors.incalf, config.colors.barren, config.colors.dryoff, config.colors.cull],
          stroke: {
            width: 5,
            // colors: cardColor
          },
          dataLabels: {
            enabled: false,
            formatter: function (val, opt) {
              return parseInt(val);
            }
          },
          legend: {
            show: false
          },
          grid: {
            padding: {
              top: 0,
              bottom: 0,
              right: 15
            }
          },
          plotOptions: {
            pie: {
              donut: {
                size: '75%',
                labels: {
                  show: true,
                  value: {
                    fontSize: '1.5rem',
                    fontFamily: 'Public Sans',
                    color: headingColor,
                    offsetY: -15
                  },
                  name: {
                    offsetY: 20,
                    fontFamily: 'Public Sans'
                  },
                  total: {
                    show: true,
                    fontSize: '0.8125rem',
                    fontWeight: '700',
                    color: axisColor,
                    label: 'Всего коров'
                  }
                }
              }
            }
          }
    });

    useEffect(() => { 
        let statusesData = null;

        let seriesArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let totalCount = 0;

        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => statusesData = json.statusValues)
            .then(() => {
                if (farm.farmVisibility) {
                    statusesData.forEach((element, index) => {
                        seriesArray[index] = seriesArray[index] + parseInt(element.replace(/ /g,''));                    
                    }); 
                }            
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {
            setSeries(seriesArray);

            seriesArray.forEach(element => {
              totalCount = totalCount + element;
            });

            if (totalCount != 0) {
              options = {
                ...options,
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        value: {
                          formatter: function (val) {                           
                            return ((parseInt(val) * 100) / totalCount).toFixed(2) + "%";
                          }
                        },
                      }
                    }
                  }
                }
              };

              setOptions(options) 
            }        
          })  
    }, [props.farms]);

    return (
        <div class="card h-100">
            <div class="card-header d-flex align-items-center justify-content-between pb-0">
            <div class="card-title mb-0">
                <h5 class="m-0 me-2 fw-semibold-2">Статистика по статусам</h5>
                <small class="text-muted fw-semibold-2">Суммарная информация по всем фермам</small>
            </div>
            </div>
            <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
                <Chart
                    options={options}
                    series={series}
                    width={190}
                    height={230}
                    type="donut"
                />
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
            </div>
            <ul class="p-0 m-0">
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-calf"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Теленок</h6>
                    <small class="text-muted">Телки младше возраста первого осеменения</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[0]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-openHeifer"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Телка (открыта)</h6>
                    <small class="text-muted">Телки старше возраста первого осеменения</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[1]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-insemHeifer"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Осеменная телка</h6>
                    <small class="text-muted">Телки до проверки стельности</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[2]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-uncalved"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Нетель</h6>
                    <small class="text-muted">Телки с установленной стельностью</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[3]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-involution"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Инвлолюция (рано)</h6>
                    <small class="text-muted">Коровы в период восстановления</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[4]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-open"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Открыта (готова)</h6>
                    <small class="text-muted">Коровы до осеменения</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[5]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-insem"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Осеменена</h6>
                    <small class="text-muted">Коровы до проверки стельности</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[6]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-incalf"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Стельная</h6>
                    <small class="text-muted">Коровы с установленной стельностью</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[7]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-barren"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Яловая</h6>
                    <small class="text-muted">Нестельные длительное время коровы</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[8]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-dryoff"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Сухостой</h6>
                    <small class="text-muted">Коровы после запуска</small>
                    </div>
                    <div class="user-progress"> 
                    <small class="fw-semibold">{series[9]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-cull"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Выбраковка</h6>
                    <small class="text-muted">Коровы на выбраковку</small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[10]}</small>
                    </div>
                </div>
                </li>
            </ul>
            </div>
        </div>
    );
  }