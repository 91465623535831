import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function HealthGrowth(props) {
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
      labels: ['Больных'],
      chart: {
        height: 240,
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
          size: 150,
          offsetY: 10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '55%'
          },
          track: {
            background: cardColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: 15,
              color: headingColor,
              fontSize: '18px',
              fontWeight: '600',
              fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
            },
            value: {
              offsetY: -25,
              color: headingColor,
              fontSize: '22px',
              fontWeight: '500',
              fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
            }
          }
        }
      },
      colors: ["#FF3E1D"],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.5,
          gradientToColors: ["#FF3E1D"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.6,
          stops: [30, 70, 100]
        }
      },
      stroke: {
        dashArray: 5
      },
      grid: {
        padding: {
          top: -35,
          bottom: -10
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        },
        active: {
          filter: {
            type: 'none'
          }
        }
      }      
    }); 
    
    let [seriesWeekly, setSeriesWeekly] = useState([]);
    let [optionsWeekly, setOptionsWeekly] = useState({
      labels: ['За неделю'],
      chart: {
        height: 240,
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
          size: 150,
          offsetY: 10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '55%'
          },
          track: {
            background: cardColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: 15,
              color: headingColor,
              fontSize: '18px',
              fontWeight: '600',
              fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
            },
            value: {
              offsetY: -25,
              color: headingColor,
              fontSize: '22px',
              fontWeight: '500',
              fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
            }
          }
        }
      },
      colors: ["#FF3E1D"],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.5,
          gradientToColors: ["#FF3E1D"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.6,
          stops: [30, 70, 100]
        }
      },
      stroke: {
        dashArray: 5
      },
      grid: {
        padding: {
          top: -35,
          bottom: -10
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        },
        active: {
          filter: {
            type: 'none'
          }
        }
      }      
    });

    let [selectedFarm, setSelectedFarm] = useState(null);

    let [deviation, setDeviation] = useState(null);

    let [deviationWeekly, setDeviationWeekly] = useState(null);

    useEffect(() => { 
      let reportListData = null;
      let healthColumnsData = null;
      let healthRowsData = null;
      let statusesData = null;

      let reportsIds = [];

      let sickCount = 0;

      let sickCountWeekly = 0;

      let totalCount = 0;

      let isSelectedFarmExist = false;

      if (props.farms.length == 0) {
        setSelectedFarm(null);
      }

      Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getReportList/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => reportListData = json.reportList)
          .then(() => {
            if (farm.farmVisibility) {
                for (let i = 0; i < reportListData.length; i++){
                  if (reportListData[i].name == "Текущие лечения") {
                    const value = {
                      farmId: farm.farmId,
                      farmName: farm.farmName,
                      reportId: reportListData[i].reportId
                    }
  
                    reportsIds.push(value);

                    farm.reportId = reportListData[i].reportId;

                    break;
                  }   
                }

                if ((selectedFarm == null) && (!isSelectedFarmExist)) {
                  setSelectedFarm(farm);     
                  
                  isSelectedFarmExist = true;
                } else if (selectedFarm != null) {
                  if (!props.farms.find(currentFarm => currentFarm.farmId == selectedFarm.farmId)) {
                    setSelectedFarm(farm);       
                }
              }        
            }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          Promise.all(reportsIds.map(report =>
            fetch("https://api.maystar.polyefir-mobile.by/getReportByFarm/" + report.farmId + "/" + report.reportId)
            .then((response) => response.json())
            .then((json) => {
              healthColumnsData = json.reportColumns;
              healthRowsData = json.reportRows;
            })
            .then(() => {
              let startDateIndex = healthColumnsData.indexOf("Дата начала лечения"); 

              healthRowsData.forEach(element => {
                var cleanDate = new Date(element.reportRowValues[startDateIndex].substr(3, 2) + "/" + element.reportRowValues[startDateIndex].substr(0, 2) + "/" + element.reportRowValues[startDateIndex].substr(6, element.reportRowValues[startDateIndex].length));

                if ((Math.abs(Date.now() - new Date(cleanDate)) <= 604800000)) {
                  sickCountWeekly = sickCountWeekly + 1;
                }    

                sickCount = sickCount + 1;
              });
            })
            .catch((error) => console.log(error))
          )) 
          .then(() => {
            Promise.all(reportsIds.map(report =>
              fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + report.farmId)
              .then((response) => response.json())
              .then((json) => statusesData = json.statusValues)
              .then(() => {
                statusesData.forEach((element, index) => {
                  totalCount = totalCount + parseInt(element.replace(/ /g,''));                    
                });
              })
              .catch((error) => console.log(error))
            )) 
            .then(() => {
              if (totalCount != 0) {
                setDeviation([((sickCount * 100) / totalCount).toFixed(2)]);  
                setDeviationWeekly([((sickCountWeekly * 100) / totalCount).toFixed(2)]);  
              } else {
                setDeviation(null);
                setDeviationWeekly(null);
              }   
            })         
          })          
        }) 
    }, [props.farms]);

    useEffect(() => { 
      let healthColumnsData = null;
      let healthRowsData = null;
      let statusesData = null;

      let sickCount = 0;

      let sickCountWeekly = 0;

      let totalCount = 0;

      if (selectedFarm != null) {
        fetch("https://api.maystar.polyefir-mobile.by/getReportByFarm/" + selectedFarm.farmId + "/" + selectedFarm.reportId)
        .then((response) => response.json())
        .then((json) => {
          healthColumnsData = json.reportColumns;
          healthRowsData = json.reportRows;
        })
        .then(() => {   
          let startDateIndex = healthColumnsData.indexOf("Дата начала лечения"); 

          healthRowsData.forEach(element => {
            var cleanDate = new Date(element.reportRowValues[startDateIndex].substr(3, 2) + "/" + element.reportRowValues[startDateIndex].substr(0, 2) + "/" + element.reportRowValues[startDateIndex].substr(6, element.reportRowValues[startDateIndex].length));

            if ((Math.abs(Date.now() - new Date(cleanDate)) <= 604800000)) {
              sickCountWeekly = sickCountWeekly + 1;
            }    

            sickCount = sickCount + 1;
          });                   
        })
        .then(() => {
          fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + selectedFarm.farmId)
          .then((response) => response.json())
          .then((json) => statusesData = json.statusValues)
          .then(() => {
            statusesData.forEach((element, index) => {
              totalCount = totalCount + parseInt(element.replace(/ /g,''));                    
            });
          })
          .then(() => {
            if (totalCount != 0) {
              setSeries([((sickCount * 100) / totalCount).toFixed(2)]);  
              setSeriesWeekly([((sickCountWeekly * 100) / totalCount).toFixed(2)]);  
            } else {
              setSeries([]);
              setSeriesWeekly([]);
            } 
          })  
          .catch((error) => console.log(error))     
        })
        .catch((error) => console.log(error))
      }
    }, [selectedFarm]);

    return (
      <div class="col-md-4">
        <div class="card-body">
        <div class="text-center">
            <div class="dropdown">
            {(selectedFarm != null) && (
              <button
              class="btn btn-sm btn-outline-primary dropdown-toggle"
              type="button"
              id="growthReportId"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >
              {selectedFarm.farmName}
              </button>
            )}
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="growthReportId">
              {props.farms.map(farm => (
                <a class="dropdown-item" href="javascript:void(0);"
                  onClick={() => {
                    setSelectedFarm(farm);                                
                  }}>{farm.farmName} | {farm.farmId}</a>      
              ))}   
            </div>
            </div>
        </div>
        </div>
        <Chart options={options} series={series} type="radialBar" height={240} />
        {(deviation != null) && (
          <div class="text-center fw-semibold pt-3 mb-5">{deviation}% Всего больных</div>
        )}
        <Chart options={optionsWeekly} series={seriesWeekly} type="radialBar" height={240} />
        {(deviationWeekly != null) && (
          <div class="text-center fw-semibold pt-3 mb-2">{deviationWeekly}% Всего за неделю</div>
        )}
      </div>
    );
  }