import React from 'react';
import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Avatar,
    Grid,
    Typography,
    TablePagination,
    TableFooter
 } from '@material-ui/core';
 import { TableSortLabel } from '@mui/material';
 import TableCellCowYield from './TableCellCowYield';
 import TableCellHerdYield from './TableCellHerdYield';
 import TableCellPr from './TableCellPr';
 import TableCellHealth from './TableCellHealth';
 import TableCellRating from './TableCellRating';

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
    },
    tableContainer: {
        border: '0 solid #d9dee3',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 6px 0 rgba(67, 89, 113, 0.12)'
    },
    tableHeaderCell: {
        textAlign: 'left',
        fontWeight: 'bold',
        backgroundColor: '#0064d7',
        color: theme.palette.getContrastText('#0064d7')
    },
    tableCell: {
        textAlign: 'left',
        color: '#566a7f'
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light)
    },
    name: {
        fontWeight: 'bold',
        color: '#566a7f'
    },
    status: {
        fontWeight: 'bold',
        fontSize: '0.75rem',
        color: 'white',
        backgroundColor: 'grey',
        borderRadius: 8,
        padding: '3px 10px',
        display: 'inline-block'
    }
  }));

export default function HomeTable(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    let [filteredFarms, setFilteredFarms] = useState([]);

    let [sortField, setSortField] = useState('asc');

    let [sort, setSort] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onSort = sortField => {
        const filteredFarmsConcat = filteredFarms.concat();
        const sortType = sort === 'asc' ? 'desc' : 'asc';
        const orderedFarms = _.orderBy(filteredFarmsConcat, sortField, sortType);
        setFilteredFarms(orderedFarms);
        setSortField(sortField);
        setSort(sortType);
    }

    useEffect(() => { 
        props.farms.map(farm => {
            if (farm.farmVisibility == false) {
                farm.status = "Скрыта";        
            } else if ((farm.farmVisibility == true) && (Math.abs(Date.now() - new Date(farm.updateDate)) <= 600000)) {
                farm.status = "В сети";   
            } else if ((farm.farmVisibility == true) && (Math.abs(Date.now() - new Date(farm.updateDate)) > 600000)) {
                farm.status = "Не в сети";   
            }    
        });

        setFilteredFarms(props.farms); 
    }, [props.farms]);

    const MyTablePagination = styled(TablePagination)(theme => ({
        color: '#566a7f'
    }));

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table" fixedHeader={true}>
                <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHeaderCell} width="20%">
                        <TableSortLabel
                            active={sortField === "farmName"}
                            direction={sort}
                            onClick={onSort.bind(null, "farmName")}>
                            Название фермы   
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="5%">
                        <TableSortLabel
                            active={sortField === "farmId"}
                            direction={sort}
                            onClick={onSort.bind(null, "farmId")}>
                            Id фермы   
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="15%">
                        <TableSortLabel
                            active={sortField === "serviceDate"}
                            direction={sort}
                            onClick={onSort.bind(null, "serviceDate")}>
                            Дата технического обслуживания   
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="10%">
                        <TableSortLabel
                            active={sortField === "cowYield"}
                            direction={sort}
                            onClick={onSort.bind(null, "cowYield")}>
                            Удой на корову, кг
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="10%">
                        <TableSortLabel
                            active={sortField === "herdYield"}
                            direction={sort}
                            onClick={onSort.bind(null, "herdYield")}>
                            Надой по стаду, т 
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="10%">
                        <TableSortLabel
                            active={sortField === "pr"}
                            direction={sort}
                            onClick={onSort.bind(null, "pr")}>
                            Индекс стельности 
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="10%">
                        <TableSortLabel
                            active={sortField === "health"}
                            direction={sort}
                            onClick={onSort.bind(null, "health")}>
                            Процент больных 
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="10%">
                        <TableSortLabel
                            active={sortField === "rating"}
                            direction={sort}
                            onClick={onSort.bind(null, "rating")}>
                            Рейтинг
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} width="10%">
                        <TableSortLabel
                            active={sortField === "status"}
                            direction={sort}
                            onClick={onSort.bind(null, "status")}>
                            Статус  
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {filteredFarms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((farm) => (
                    <TableRow key={farm.farmName}>
                    <TableCell className={classes.tableCell} width="20%">
                        <Typography className={classes.name}>{farm.farmName}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} width="5%">
                        {farm.farmId}
                    </TableCell>
                    <TableCell className={classes.tableCell} width="15%">{((new Date(farm.serviceDate).getDate() < 10) ? "0" + (new Date(farm.serviceDate).getDate()) : new Date(farm.serviceDate).getDate()) + "." + ((new Date(farm.serviceDate).getMonth() + 1 < 10) ? "0" + (new Date(farm.serviceDate).getMonth() + 1) : new Date(farm.serviceDate).getMonth() + 1) + "." + new Date(farm.serviceDate).getFullYear()}</TableCell>
                    <TableCellCowYield farms={props.farms} farmId={farm.farmId}></TableCellCowYield>
                    <TableCellHerdYield farms={props.farms} farmId={farm.farmId}></TableCellHerdYield>
                    <TableCellPr farms={props.farms} farmId={farm.farmId}></TableCellPr>
                    <TableCellHealth farms={props.farms} farmId={farm.farmId}></TableCellHealth>
                    <TableCellRating farms={props.farms} farmsRatings={props.farmsRatings} farmId={farm.farmId}>{farm.rating}</TableCellRating>
                    <TableCell className={classes.tableCell} width="10%">
                        {(farm.farmVisibility == false) && (
                            <Typography 
                            className={classes.status}
                            style={{
                                backgroundColor: '#8592a3'
                            }}
                            >Скрыта</Typography>
                        )}
                        {(farm.farmVisibility == true) && (Math.abs(Date.now() - new Date(farm.updateDate)) <= 600000) && (
                            <Typography 
                            className={classes.status}
                            style={{
                                backgroundColor: '#71dd37'
                            }}
                            >В сети</Typography>
                        )}
                        {(farm.farmVisibility == true) && (Math.abs(Date.now() - new Date(farm.updateDate)) > 600000) && (
                            <Typography 
                            className={classes.status}
                            style={{
                                backgroundColor: '#ff3e1d'
                            }}
                            >Не в сети</Typography>
                        )}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                    <TableCell colSpan={9}>
                        <MyTablePagination 
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={filteredFarms.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={"Ферм на странице"}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
  }