import React, { Component } from 'react'; 
import useScript from './hooks/useScript'; 
import { Navigate } from "react-router-dom";
import Chart from "react-apexcharts";
import './assets/vendor/js/bootstrap.js';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import App from './App';
import CowYieldsMonthlyChart from './CowYieldsMonthlyChart';
import HerdYieldsMonthlyChart from './HerdYieldsMonthlyChart';
import CowYieldsDailyChart from './CowYieldsDailyChart';
import HerdYieldsDailyChart from './HerdYieldsDailyChart';
import GroupsChart from './GroupsChart';
import YieldChart from './YieldChart';
import MilkFlowChart from './MilkFlowChart'; 
import CowsPerHourChart from './CowsPerHourChart';
import MilkingDurationChart from './MilkingDurationChart';
import YieldDurationChart from './YieldDurationChart';
import NonStandardYieldChart from './NonStandardYieldChart';
import ConductivityChart from './ConductivityChart';
import TotalHerdYield from './TotalHerdYield';
import TotalCowYield from './TotalCowYield';
import TotalConductivity from './TotalConductivity';
import MilkRating from './MilkRating';
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"];

const currentDate = new Date();

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

const Milk = props => {   
    useScript('./assets/vendor/libs/jquery/jquery.js');
    useScript('./assets/vendor/libs/popper/popper.js');
    useScript('./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js');
    useScript('./assets/vendor/js/menu.js');
    useScript('./assets/vendor/libs/apex-charts/apexcharts.js');
    useScript('./assets/js/main.js');
    useScript('./assets/js/dashboards-analytics.js');
    useScript('https://buttons.github.io/buttons.js');

    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
          method: 'POST', 
          mode: 'cors', 
          cache: 'no-cache', 
          credentials: 'same-origin', 
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow', 
          referrerPolicy: 'no-referrer', 
          body: JSON.stringify(data) 
        });
        return response.json(); 
    }

    const [farmsData, setFarmsData] = useState([]);

    const [open, setOpen] = useState(false);

    const [openExist, setOpenExist] = useState(false);

    const [newFarmId, setNewFarmId] = useState(1);

    const [newLogin, setNewLogin] = useState("");

    const [newPassword, setNewPassword] = useState("");

    const [errorText, setErrorText] = useState("");

    const handleChange = (event, newValue) => {
        if (newValue != null) {
            const index = props.selectedFarmsData.findIndex(currentFarm => currentFarm.farmId == newValue.split(" | ")[1]);

            if (index == -1) {
                setNewFarmId(newValue.split(" | ")[1]);

                setOpen(true);
            } else {
                setOpenExist(true);
            }
        }
    };

    const changeLogin = (e) => {
        let input = e.target.value;
  
        setNewLogin(input);
    };

    const changePassword = (e) => {
        let input = e.target.value;
  
        setNewPassword(input);
    };

    const handleSave = () => {
        let user = null;

        postData("https://api.maystar.polyefir-mobile.by/loginByFarm", { farmId: newFarmId, login: newLogin, password: newPassword })
        .then((response) => user = response)
        .then(() => { 
            if (user.login != null) {
                const currentFarm = farmsData.find(farm => farm.farmId == user.farmId);

                var cleanUpdateDate = new Date(currentFarm.updateDate.substr(3, 2) + "/" + currentFarm.updateDate.substr(0, 2) + "/" + currentFarm.updateDate.substr(6, currentFarm.updateDate.length));          
                var cleanServiceDate = new Date(currentFarm.serviceDate.substr(3, 2) + "/" + currentFarm.serviceDate.substr(0, 2) + "/" + currentFarm.serviceDate.substr(6, currentFarm.serviceDate.length));

                const value = {
                    farmId: currentFarm.farmId,
                    farmName: currentFarm.farmName,
                    updateDate: cleanUpdateDate,
                    serviceDate: cleanServiceDate,
                    cowYield: 0.0,
                    herdYield: 0.0,
                    pr: 0.0,
                    health: "Нет данных",
                    rating: 0.0,
                    farmVisibility: true
                }

                const selectedFarms = [...props.selectedFarmsData];
                selectedFarms.push(value);
                props.setSelectedFarmsData(selectedFarms);  
               
                const login = localStorage.getItem("login");

                let farmsIds = "";
                let farmsVisibility = "";

                selectedFarms.forEach(element => {
                    farmsIds = farmsIds + element.farmId + ","; 
                    if (element.farmVisibility) {
                        farmsVisibility = farmsVisibility + "true" + ",";  
                    } else {
                        farmsVisibility = farmsVisibility + "false" + ",";  
                    }            
                });

                farmsIds = farmsIds.slice(0, -1);
                farmsVisibility = farmsVisibility.slice(0, -1);

                postData("https://api.maystar.polyefir-mobile.by/updateFarmsToManager", { login: login, farmsIds: farmsIds, farmsVisibility: farmsVisibility })
                .catch((error) => console.log(error));

                setOpen(false);
            } else {
                setErrorText("Неверное имя пользователя или пароль"); 
            }
        })
      .catch((error) => console.log(error));
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseExist = () => {
        setOpenExist(false);
    };

    useEffect(() => { 
        document.title = "Статистика | Молоко"; 
    });

    useEffect(() => { 
        let farmsArray = [];
        let selectedFarmsArray = [];

        const login = localStorage.getItem("login");
        const password = localStorage.getItem("password");

        let manager = null;

        postData("https://api.maystar.polyefir-mobile.by/loginManager", { login: login, password: password })
        .then((response) => manager = response)
        .then(() => {
            fetch("https://api.maystar.polyefir-mobile.by/getAllFarmsInfo")
            .then((response) => response.json())
            .then((json) => farmsArray = json.farms)
            .then(() => {
                setFarmsData(farmsArray);
    
                const farmsIds = manager.farmsIds;
                const farmsVisibility = manager.farmsVisibility;
            
                if (farmsIds != null) {
                    farmsIds.split(",").forEach((element, index) => {
                        const currentFarm = farmsArray.find(farm => farm.farmId == element);
    
                        var cleanUpdateDate = new Date(currentFarm.updateDate.substr(3, 2) + "/" + currentFarm.updateDate.substr(0, 2) + "/" + currentFarm.updateDate.substr(6, currentFarm.updateDate.length));          
                        var cleanServiceDate = new Date(currentFarm.serviceDate.substr(3, 2) + "/" + currentFarm.serviceDate.substr(0, 2) + "/" + currentFarm.serviceDate.substr(6, currentFarm.serviceDate.length));
    
                        let farmVisibility;
                        
                        if (farmsVisibility.split(",")[index] == "true") {
                            farmVisibility = true;
                        } else {
                            farmVisibility = false;
                        }
    
                        const value = {
                            farmId: currentFarm.farmId,
                            farmName: currentFarm.farmName,
                            updateDate: cleanUpdateDate,
                            serviceDate: cleanServiceDate,
                            cowYield: 0.0,
                            herdYield: 0.0,
                            pr: 0.0,
                            health: "Нет данных",
                            rating: 0.0,
                            farmVisibility: farmVisibility
                        }
    
                        selectedFarmsArray.push(value);
                    });
    
                    props.setSelectedFarmsData(selectedFarmsArray);
                }
            })
            .catch((error) => console.log(error));        
        })
        .catch((error) => console.log(error));
    }, []);

    const login = localStorage.getItem("login");

    if (login == null) {
        return <Navigate replace to="/login" />;
    } else { 
        return (
            <div class="layout-wrapper layout-content-navbar">
            <div class="layout-container">
                <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
                <div class="app-brand demo">
                    <a href="/" class="app-brand-link">
                    <span class="app-brand-logo demo">
                        <img src="../assets/img/logo/webLogo.png" alt class="w-px-50" />  
                    </span>
                    <span class="app-brand-text demo menu-text fw-bolder ms-2">Майстар</span>
                    </a>
        
                    <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                    <i class="bx bx-chevron-left bx-sm align-middle"></i>
                    </a>
                </div>
        
                <div class="menu-inner-shadow"></div>
        
                <ul class="menu-inner py-1">
                    <li class="menu-item">
                    <a href="/" class="menu-link">
                        <i class="menu-icon tf-icons fa-solid fa-house"></i>
                        <div data-i18n="Analytics">Главная</div>
                    </a>
                    </li>
            
                    <li class="menu-header small text-uppercase"><span class="menu-header-text">Статистика</span></li>
        
                    <li class="menu-item">
                    <a href="/reproduction" class="menu-link">
                        <i class="menu-icon tf-icons fa-solid fa-cow"></i>
                        <div data-i18n="Reproduction">Воспроизводство</div>
                    </a>
                    </li>
                    <li class="menu-item">
                    <a href="/health" class="menu-link">
                        <i class="menu-icon tf-icons fa-solid fa-briefcase-medical"></i>
                        <div data-i18n="Health">Здоровье</div>
                    </a>
                    </li>
                    <li class="menu-item active">
                    <a href="/milk" class="menu-link">
                        <i class="menu-icon tf-icons fa-solid fa-droplet"></i>
                        <div data-i18n="Milk">Молоко</div>
                    </a>
                    </li>  
                    <li class="menu-item">
                    <a href="/tech" class="menu-link">
                        <i class="menu-icon tf-icons fa-solid fa-gear"></i>
                        <div data-i18n="Milk">Техническое состояние</div>
                    </a>
                    </li> 

                    <li class="menu-header small text-uppercase"><span class="menu-header-text">Аналитика</span></li>

                    <li class="menu-item">
                    <a href="/goals" class="menu-link">
                        <i class="menu-icon tf-icons fa-solid fa-bullseye"></i>
                        <div data-i18n="Goals">Цели</div>
                    </a>
                    </li>  

                    <li class="menu-item">
                    <a href="/forecasts" class="menu-link">
                        <i class="menu-icon tf-icons fa-solid fa-magnifying-glass-chart"></i>
                        <div data-i18n="Forecasts">Прогнозы</div>
                    </a>
                    </li>  
                </ul>
                </aside>
                
                <div class="layout-page">
                
                <nav
                    class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                    id="layout-navbar">
                    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                    <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0);">
                        <i class="bx bx-menu bx-sm"></i>
                    </a>
                    </div>
        
                    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
            
                    <div class="navbar-nav align-items-center">
                        <div class="nav-item d-flex align-items-center">
                        <i class="fs-4 lh-0 fa-solid fa-magnifying-glass"></i>
                        <Autocomplete
                            disablePortal    
                            id="free-solo" 
                            noOptionsText={"Фермы не найдены"}
                            options={farmsData.map((farm) => farm.farmName + " | " + farm.farmId)}
                            sx={{ width: 200, marginLeft: 2 }}
                            onChange={handleChange}
                            renderInput={(params) => <TextField {...params} sx={{ input: { color: "#697a8d" } }} variant="standard" placeholder="Поиск фермы"
                            InputProps={{ ...params.InputProps, disableUnderline: true }} />}
                        />
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle style={{ fontWeight: 'bold' }}>Добавить ферму</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="login"
                                    label="Логин"
                                    fullWidth
                                    variant="standard"
                                    value={newLogin}
                                    error={errorText.length === 0 ? false : true }
                                    helperText={errorText}
                                    onChange={changeLogin}
                                />
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="password"
                                    type="password"
                                    label="Пароль"
                                    fullWidth
                                    variant="standard"
                                    value={newPassword}
                                    error={errorText.length === 0 ? false : true }
                                    helperText={errorText}
                                    onChange={changePassword}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button style={{ fontWeight: 'bold' }} onClick={handleClose}>Отмена</Button>
                                <Button style={{ fontWeight: 'bold' }} onClick={handleSave}>Добавить</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openExist} onClose={handleCloseExist}>
                            <DialogTitle style={{ fontWeight: 'bold' }}>Ферма уже добавлена</DialogTitle>
                            <DialogActions>
                                <Button style={{ fontWeight: 'bold' }} onClick={handleCloseExist}>Ок</Button>
                            </DialogActions>
                        </Dialog>
                        </div>
                    </div>
        
                    <ul class="navbar-nav flex-row align-items-center ms-auto ul-max-width d-flex-wrap ul-max-width">
                        {props.selectedFarmsData.map(farm => (
                            <li key={farm.farmId} class="nav-item navbar-dropdown dropdown-user dropdown">
                            <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                                {(!farm.farmVisibility) && (  
                                    <div class="avatar avatar-offline">
                                        <img src="../assets/img/avatars/farm-avatar-alt.png" alt class="w-px-40 h-auto rounded-circle" />
                                    </div>
                                )}
                                {(Math.abs(Date.now() - new Date(farm.updateDate)) <= 600000) && (farm.farmVisibility) && (  
                                    <div class="avatar avatar-online">
                                        <img src="../assets/img/avatars/farm-avatar-alt.png" alt class="w-px-40 h-auto rounded-circle" />
                                    </div>
                                )}
                                {(Math.abs(Date.now() - new Date(farm.updateDate)) > 600000) && (farm.farmVisibility) && (
                                    <div class="avatar avatar-busy">
                                        <img src="../assets/img/avatars/farm-avatar-alt.png" alt class="w-px-40 h-auto rounded-circle" />
                                    </div>
                                )}
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end"> 
                                <li>
                                <a class="dropdown-item" href="javascript:void(0);">
                                    <div class="d-flex">
                                    <div class="flex-shrink-0 me-3"> 
                                        {(!farm.farmVisibility) && (  
                                            <div class="avatar avatar-offline">
                                                <img src="../assets/img/avatars/farm-avatar-alt.png" alt class="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        )}
                                        {(Math.abs(Date.now() - new Date(farm.updateDate)) <= 600000) && (farm.farmVisibility) && (  
                                            <div class="avatar avatar-online">
                                                <img src="../assets/img/avatars/farm-avatar-alt.png" alt class="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        )}
                                        {(Math.abs(Date.now() - new Date(farm.updateDate)) > 600000) && (farm.farmVisibility) && (
                                            <div class="avatar avatar-busy">
                                                <img src="../assets/img/avatars/farm-avatar-alt.png" alt class="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        )}
                                    </div>
                                    <div class="flex-grow-1">
                                        <span class="fw-semibold d-block">{farm.farmName}</span>
                                        <small class="text-muted">ID фермы: {farm.farmId}</small>
                                    </div> 
                                    </div>
                                </a>
                                </li>
                                <li>
                                <div class="dropdown-divider"></div>
                                </li>
                                <li>
                                {farm.farmVisibility && (
                                    <a class="dropdown-item" href="javascript:void(0);"
                                        onClick={() => {
                                            farm.farmVisibility = !farm.farmVisibility;
                                            const index = props.selectedFarmsData.findIndex(currentFarm => currentFarm.farmId === farm.farmId);
                                            const selectedFarms = [...props.selectedFarmsData];
                                            selectedFarms[index] = farm;
                                            props.setSelectedFarmsData(selectedFarms);   
                                            
                                            const login = localStorage.getItem("login");

                                            let farmsIds = "";
                                            let farmsVisibility = "";

                                            selectedFarms.forEach(element => {
                                                farmsIds = farmsIds + element.farmId + ",";  
                                                if (element.farmVisibility) {
                                                    farmsVisibility = farmsVisibility + "true" + ",";  
                                                } else {
                                                    farmsVisibility = farmsVisibility + "false" + ",";  
                                                }
                                            });

                                            farmsIds = farmsIds.slice(0, -1);
                                            farmsVisibility = farmsVisibility.slice(0, -1);

                                            postData("https://api.maystar.polyefir-mobile.by/updateFarmsToManager", { login: login, farmsIds: farmsIds, farmsVisibility: farmsVisibility })
                                            .catch((error) => console.log(error));
                                        }}>
                                        <i class="fa-solid fa-minus me-2"></i>
                                        <span class="align-middle">Скрыть ферму</span>
                                    </a>
                                )} 
                                {!farm.farmVisibility && (
                                    <a class="dropdown-item" href="javascript:void(0);"
                                            onClick={() => {
                                                farm.farmVisibility = !farm.farmVisibility;
                                                const index = props.selectedFarmsData.findIndex(currentFarm => currentFarm.farmId === farm.farmId);
                                                const selectedFarms = [...props.selectedFarmsData];
                                                selectedFarms[index] = farm;
                                                props.setSelectedFarmsData(selectedFarms); 

                                                const login = localStorage.getItem("login");

                                                let farmsIds = "";
                                                let farmsVisibility = "";

                                                selectedFarms.forEach(element => {
                                                    farmsIds = farmsIds + element.farmId + ",";  
                                                    if (element.farmVisibility) {
                                                        farmsVisibility = farmsVisibility + "true" + ",";  
                                                    } else {
                                                        farmsVisibility = farmsVisibility + "false" + ",";  
                                                    }
                                                });
        
                                                farmsIds = farmsIds.slice(0, -1);
                                                farmsVisibility = farmsVisibility.slice(0, -1);

                                                postData("https://api.maystar.polyefir-mobile.by/updateFarmsToManager", { login: login, farmsIds: farmsIds, farmsVisibility: farmsVisibility })
                                                .catch((error) => console.log(error));
                                            }}>
                                            <i class="fa-solid fa-plus me-2"></i>
                                            <span class="align-middle">Показать ферму</span>
                                        </a>
                                    )} 
                                </li>
                                <li>
                                <div class="dropdown-divider"></div>
                                </li>
                                <li>
                                <a class="dropdown-item" href="javascript:void(0);"
                                    onClick={() => {
                                        const index = props.selectedFarmsData.findIndex(currentFarm => currentFarm.farmId === farm.farmId);
                                        const selectedFarms = [...props.selectedFarmsData];
                                        selectedFarms.splice(index, 1);
                                        props.setSelectedFarmsData(selectedFarms);   
                                        
                                        const login = localStorage.getItem("login");

                                        let farmsIds = "";
                                        let farmsVisibility = "";

                                        selectedFarms.forEach(element => {
                                            farmsIds = farmsIds + element.farmId + ",";  
                                            if (element.farmVisibility) {
                                                farmsVisibility = farmsVisibility + "true" + ",";  
                                            } else {
                                                farmsVisibility = farmsVisibility + "false" + ",";  
                                            }
                                        });

                                        farmsIds = farmsIds.slice(0, -1);
                                        farmsVisibility = farmsVisibility.slice(0, -1);

                                        postData("https://api.maystar.polyefir-mobile.by/updateFarmsToManager", { login: login, farmsIds: farmsIds, farmsVisibility: farmsVisibility })
                                        .catch((error) => console.log(error));
                                    }}>
                                    <i class="fa-solid fa-trash me-2"></i>
                                    <span class="align-middle">Удалить ферму</span>
                                </a>
                                </li>
                            </ul>
                            </li>     
                        ))}
                    </ul>
                    </div>
                </nav>
        
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                    <div class="row">
                        <div class="col-lg-8 mb-4 order-0">
                        <div class="card">
                            <div class="d-flex align-items-end row">
                            <div class="col-sm-7">
                                <div class="card-body">
                                    <h5 class="fw-semibold card-title text-primary mb-3-2">Сводная информация по всем фермам</h5>
                                    <p class="fw-semibold mb-3-1">
                                        Суммарный надой за сутки <TotalHerdYield farms={props.selectedFarmsData}></TotalHerdYield> т
                                        <br/>Средний удой на корову за сутки <TotalCowYield farms={props.selectedFarmsData}></TotalCowYield> кг 
                                        <br/>Средняя электропроводность за дойку <TotalConductivity farms={props.selectedFarmsData}></TotalConductivity> мкСм/см
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-5 text-sm-left">
                                <div class="pb-0 px-0 px-md-0 text-center">
                                <img
                                    src="../assets/img/illustrations/farm.svg"
                                    height="150"
                                    text-align="center"
                                    alt="Farm"
                                    data-app-dark-img="illustrations/farm.svg"
                                    data-app-light-img="illustrations/farm.svg"
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <MilkRating farms={props.selectedFarmsData}></MilkRating>
                    </div>
                    <div class="row"> 
                        <div class="col-12 col-lg-8 order-0 mb-4">
                            <div class="row mb-4 margin-row">
                                <div class="card h-100">
                                    <div class="card-header">
                                    <ul class="nav nav-pills" role="tablist">
                                        <li class="nav-item">
                                            <button
                                                type="button"
                                                class="nav-link fw-semibold active"
                                                role="tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#cowYieldsMonthlyTab"
                                                aria-controls="cowYieldsMonthlyTab"
                                                aria-selected="true">
                                                Корова
                                            </button>
                                            </li>
                                            <li class="nav-item">
                                            <button type="button"
                                                class="nav-link fw-semibold" 
                                                role="tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#herdYieldsMonthlyTab"
                                                aria-controls="herdYieldsMonthlyTab"
                                                aria-selected="false">
                                                Стадо
                                            </button>
                                        </li>
                                    </ul>
                                    </div>
                                    <div class="card-body px-0">
                                    <div class="tab-content p-0">
                                        <CowYieldsMonthlyChart farms={props.selectedFarmsData} />
                                        <HerdYieldsMonthlyChart farms={props.selectedFarmsData} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4 margin-row">
                                <div class="card h-100">
                                    <div class="card-header">
                                    <ul class="nav nav-pills" role="tablist">
                                        <li class="nav-item">
                                            <button
                                                type="button"
                                                class="nav-link fw-semibold active"
                                                role="tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#cowYieldsDailyTab"
                                                aria-controls="cowYieldsDailyTab"
                                                aria-selected="true">
                                                Корова
                                            </button>
                                            </li>
                                            <li class="nav-item">
                                            <button type="button"
                                                class="nav-link fw-semibold" 
                                                role="tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#herdYieldsDailyTab"
                                                aria-controls="herdYieldsDailyTab"
                                                aria-selected="false">
                                                Стадо
                                            </button>
                                        </li>
                                    </ul>
                                    </div>
                                    <div class="card-body px-0">
                                    <div class="tab-content p-0">
                                        <CowYieldsDailyChart farms={props.selectedFarmsData} />
                                        <HerdYieldsDailyChart farms={props.selectedFarmsData} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row margin-row">
                                <div class="card h-100">
                                <div class="card-header">
                                    <ul class="nav nav-pills" role="tablist">
                                    <li class="nav-item">
                                        <button
                                            type="button"
                                            class="nav-link fw-semibold active"
                                            role="tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#yieldTab"
                                            aria-controls="yieldTab"
                                            aria-selected="true">
                                            Надой
                                        </button>
                                        </li>
                                        <li class="nav-item">
                                        <button type="button"
                                            class="nav-link fw-semibold" 
                                            role="tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#milkFlowTab"
                                            aria-controls="milkFlowTab"
                                            aria-selected="false">
                                            Молокоотд.
                                        </button>
                                        </li>
                                        <li class="nav-item">
                                        <button type="button"
                                            class="nav-link fw-semibold" 
                                            role="tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#cowsPerHourTab"
                                            aria-controls="cowsPerHourTab"
                                            aria-selected="false">
                                            Короводоек/час
                                        </button>
                                        </li>
                                        <li class="nav-item">
                                        <button type="button"
                                            class="nav-link fw-semibold" 
                                            role="tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#milkingDurationTab"
                                            aria-controls="milkingDurationTab"
                                            aria-selected="false">
                                            Длит. дойки
                                        </button>
                                        </li>
                                        <li class="nav-item">
                                        <button type="button"
                                            class="nav-link fw-semibold" 
                                            role="tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#yieldDurationTab"
                                            aria-controls="yieldDurationTab"
                                            aria-selected="false">
                                            Время доения
                                        </button>
                                        </li>
                                        <li class="nav-item">
                                        <button type="button"
                                            class="nav-link fw-semibold" 
                                            role="tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nonStandartYieldTab"
                                            aria-controls="nonStandartYieldTab"
                                            aria-selected="false">
                                            Нестанд. доений
                                        </button>
                                        </li> 
                                        <li class="nav-item">
                                        <button type="button"
                                            class="nav-link fw-semibold" 
                                            role="tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#conductivityTab"
                                            aria-controls="conductivityTab"
                                            aria-selected="false">
                                            Электропроводность
                                        </button>
                                        </li>                                   
                                    </ul>
                                    </div>
                                    <div class="card-body px-0">
                                    <div class="tab-content p-0">
                                        <YieldChart farms={props.selectedFarmsData} />
                                        <MilkFlowChart farms={props.selectedFarmsData} />
                                        <CowsPerHourChart farms={props.selectedFarmsData} />
                                        <MilkingDurationChart farms={props.selectedFarmsData} />
                                        <YieldDurationChart farms={props.selectedFarmsData} />
                                        <NonStandardYieldChart farms={props.selectedFarmsData} />
                                        <ConductivityChart farms={props.selectedFarmsData} />
                                    </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
        
                        <div class="col-md-6 col-lg-4 order-1 mb-4">
                            <GroupsChart farms={props.selectedFarmsData} />
                        </div>
                    </div>
                    </div>
        
                    <footer class="content-footer footer bg-footer-theme">
                    <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                        <div class="mb-2 mb-md-0">
                        © {new Date().getFullYear()}, made by&nbsp; 
                        <a href="https://polyefir-agro.by" target="_blank" class="footer-link fw-bolder">Polyefir AGRO</a>
                        </div>
                    </div>
                    </footer>
        
                    <div class="content-backdrop fade"></div>
                </div>
                </div>
            </div>
        
            <div class="layout-overlay layout-menu-toggle"></div>  
            </div> 
        );
    }
}
    
export default Milk;  