import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function TotalHealthPercentWeekly(props) {
    let [deviationWeekly, setDeviationWeekly] = useState(null);

    useEffect(() => { 
      let reportListData = null;
      let healthColumnsData = null;
      let healthRowsData = null;
      let statusesData = null;

      let reportsIds = [];

      let sickCountWeekly = 0;

      let totalCount = 0;

      Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getReportList/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => reportListData = json.reportList)
          .then(() => {
            if (farm.farmVisibility) {
                for (let i = 0; i < reportListData.length; i++){
                  if (reportListData[i].name == "Текущие лечения") {
                    const value = {
                      farmId: farm.farmId,
                      farmName: farm.farmName,
                      reportId: reportListData[i].reportId
                    }
  
                    reportsIds.push(value);

                    break;
                  }   
                }       
            }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          Promise.all(reportsIds.map(report =>
            fetch("https://api.maystar.polyefir-mobile.by/getReportByFarm/" + report.farmId + "/" + report.reportId)
            .then((response) => response.json())
            .then((json) => {
              healthColumnsData = json.reportColumns;
              healthRowsData = json.reportRows;
            })
            .then(() => {
              let startDateIndex = healthColumnsData.indexOf("Дата начала лечения"); 

              healthRowsData.forEach(element => {
                var cleanDate = new Date(element.reportRowValues[startDateIndex].substr(3, 2) + "/" + element.reportRowValues[startDateIndex].substr(0, 2) + "/" + element.reportRowValues[startDateIndex].substr(6, element.reportRowValues[startDateIndex].length));

                if ((Math.abs(Date.now() - new Date(cleanDate)) <= 604800000)) {
                  sickCountWeekly = sickCountWeekly + 1;
                }    
              });
            })
            .catch((error) => console.log(error))
          )) 
          .then(() => {
            Promise.all(reportsIds.map(report =>
              fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + report.farmId)
              .then((response) => response.json())
              .then((json) => statusesData = json.statusValues)
              .then(() => {
                statusesData.forEach((element, index) => {
                  totalCount = totalCount + parseInt(element.replace(/ /g,''));                    
                });
              })
              .catch((error) => console.log(error))
            )) 
            .then(() => {
              if (totalCount != 0) {
                setDeviationWeekly([((sickCountWeekly * 100) / totalCount).toFixed(2)]);  
              } else {
                setDeviationWeekly(null);
              }   
            })         
          })          
        }) 
    }, [props.farms]);

    return (
      <span class="fw-bold">{deviationWeekly}</span>
    );
  }