import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1',
      calf: '#808080',
      openHeifer: '#8381ff',
      insemHeifer: '#90ff00',
      uncalved: '#57ffff',
      involution: '#f9b9a1',
      open: '#7e0cff',
      insem: '#25803d',
      incalf: '#3ba7ff',
      barren: '#f5009d',
      dryoff: '#a28200',
      cull: '#f9bf00'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

let maxValueCowYield = 30;
let minValueCowYield = 0;
let valueToPercentCowYield = (val) => ( (val - minValueCowYield) * 100 ) / (maxValueCowYield - minValueCowYield);

let maxValueHerdYield = 30;
let minValueHerdYield = 0;
let valueToPercentHerdYield = (val) => ( (val - minValueHerdYield) * 100 ) / (maxValueHerdYield - minValueHerdYield);

export default function MilkRating(props) {
    async function postData(url = '', data = {}) {
      const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(data) 
      });
      return response.json(); 
    }

    const [minRating, setMinRating] = useState(0);

    const [maxRating, setMaxRating] = useState(0);

    const [minRatingFarm, setMinRatingFarm] = useState("");

    const [maxRatingFarm, setMaxRatingFarm] = useState("");

    useEffect(() => { 
      let cowYieldsData = null;
      let herdYieldsData = null;

      let value = 0;

      let maxRating = -1;
      let minRating = 9999;

      let maxRatingFarm = "";
      let minRatingFarm = "";

      const login = localStorage.getItem("login");
      const password = localStorage.getItem("password");

      let manager = null;

      postData("https://api.maystar.polyefir-mobile.by/loginManager", { login: login, password: password })
      .then((response) => manager = response)
      .then(() => {
        maxValueCowYield = parseFloat(manager.cowYieldGoal);
        valueToPercentCowYield = (val) => ( (val - minValueCowYield) * 100 ) / (maxValueCowYield - minValueCowYield);

        maxValueHerdYield = parseFloat(manager.herdYieldGoal);
        valueToPercentHerdYield = (val) => ( (val - minValueHerdYield) * 100 ) / (maxValueHerdYield - minValueHerdYield);

        Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => {
            cowYieldsData = json.productivityYieldByCow;
            herdYieldsData = json.productivityYieldByHerd;
          })
          .then(() => {  
              if (farm.farmVisibility) {
                  if (cowYieldsData[cowYieldsData.length - 1] != undefined) {
                    value = valueToPercentCowYield(parseFloat(cowYieldsData[cowYieldsData.length - 1]));
                  } else {
                    value = 0;
                  }  
                  
                  if (herdYieldsData[herdYieldsData.length - 1] != undefined) {
                    value = value + valueToPercentHerdYield(parseFloat(herdYieldsData[herdYieldsData.length - 1]));   
                  }

                  if (value < minRating) {
                    minRating = value;
                    minRatingFarm = farm.farmName;
                  }

                  if (value > maxRating) {
                    maxRating = value;
                    maxRatingFarm = farm.farmName;
                  }
              }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          setMinRating(minRating);
          setMaxRating(maxRating);

          setMinRatingFarm(minRatingFarm);
          setMaxRatingFarm(maxRatingFarm);
        })
      })
      .catch((error) => console.log(error));    
    }, [props.farms]);

    return (
      <div class="col-lg-4 col-md-4 order-1">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-6 mb-4">
            <div class="card">
                <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                    <div class="avatar flex-shrink-0">
                        <i class="fa-solid fa-thumbs-up icon-best"></i>
                    </div>
                </div>
                <span class="fw-semibold">{maxRatingFarm}</span>
                <h3 class="card-title mb-1">{maxRating.toFixed(0)}</h3>
                <small class="text-success fw-bold">Лучший рейтинг</small>
                </div>
            </div>
            </div>
            <div class="col-lg-6 col-md-12 col-6 mb-4">
            <div class="card">
                <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                    <div class="avatar flex-shrink-0">
                        <i class="fa-solid fa-thumbs-down icon-worst"></i>
                    </div>
                </div>
                <span class="fw-semibold">{minRatingFarm}</span>
                <h3 class="card-title text-nowrap mb-1">{minRating.toFixed(0)}</h3>
                <small class="text-danger fw-bold">Худший рейтинг</small>
                </div>
            </div>
            </div>
        </div>
      </div>
    );
  }