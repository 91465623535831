import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1',
      calf: '#808080',
      openHeifer: '#8381ff',
      insemHeifer: '#90ff00',
      uncalved: '#57ffff',
      involution: '#f9b9a1',
      open: '#7e0cff',
      insem: '#25803d',
      incalf: '#3ba7ff',
      barren: '#f5009d',
      dryoff: '#a28200',
      cull: '#f9bf00'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

let maxValue = 5;
let minValue = 10;
let valueToPercent = (val) => ( (val - minValue) * 100 ) / (maxValue - minValue);

export default function LamenessGoal(props) {
    const chartRef = useRef();

    async function postData(url = '', data = {}) {
      const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(data) 
      });
      return response.json(); 
    }

    let [baseSeries, setBaseSeries] = useState([]);
    let [farmsSeries, setFarmsSeries] = useState([]);

    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
      chart: {
        height: 400,
        width: 400,
        type: 'radialBar'
      },
      labels: [],
      stroke: {
        lineCap: 'round'
      },
      legend: {
        show: false
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px'
            },
            value: {
              formatter: (val) => ( val * (maxValue - minValue) ) / 100 + minValue + "%",
              fontSize: '16px',
              color: '#566a7f'
            },
            total: {
              show: true,
              label: 'Цель',
              formatter: function (w) {
                return 'Менее ' + goal + '%'
              }
            }
          }
        }
      }
    });

    const [open, setOpen] = useState(false);

    const [goal, setGoal] = useState(5);

    const [newGoal, setNewGoal] = useState(5);

    const [errorText, setErrorText] = useState("");

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleSave = () => {
      if (parseFloat(newGoal)) {
        setGoal(parseFloat(newGoal));
        setNewGoal(parseFloat(newGoal));

        maxValue = parseFloat(newGoal);
        valueToPercent = (val) => ( (val - minValue) * 100 ) / (maxValue - minValue);

        let seriesArray = [];

        baseSeries.forEach(element => {
          seriesArray.push(valueToPercent(element)); 
        });

        setSeries(seriesArray);

        chartRef.current.chart.ctx.updateOptions({
          ...options,
          labels: farmsSeries,
          plotOptions: {
            radialBar: { 
              dataLabels: {
                value: {
                  formatter: (val) => ( val * (maxValue - minValue) ) / 100 + minValue + "%"
                },
                total: {
                  formatter: function (w) {
                    return "Менее " + parseFloat(newGoal) + "%"
                  }
                }
              }
            }
          }
        });

        const login = localStorage.getItem("login");

        postData("https://api.maystar.polyefir-mobile.by/setLamenessGoal", { login: login, lamenessGoal: parseFloat(newGoal) })
        .catch((error) => console.log(error));

        setOpen(false);
      } else {
        setErrorText("Неверный формат");
      }       
    };

    const handleClose = () => {
      setOpen(false);
    };

    const changeGoal = (e) => {
      let input = e.target.value;

      if(!input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
        setErrorText("Введите число");
      } else {
        setErrorText("");
      }

      setNewGoal(input);
    };

    useEffect(() => { 
      let reportListData = null;
      let healthColumnsData = null;
      let healthRowsData = null;
      let statusesData = null;

      let reportsIds = [];

      let sickArray = [];
      let totalArray = [];
      let baseSeriesArray = [];
      let seriesArray = [];
      let farmsArray = [];

      let sickCount = 0;

      let totalCount = 0; 

      const login = localStorage.getItem("login");
      const password = localStorage.getItem("password");

      let manager = null;

      postData("https://api.maystar.polyefir-mobile.by/loginManager", { login: login, password: password })
      .then((response) => manager = response)
      .then(() => {
        setGoal(parseFloat(manager.lamenessGoal));
        setNewGoal(parseFloat(manager.lamenessGoal));

        maxValue = parseFloat(manager.lamenessGoal);
        valueToPercent = (val) => ( (val - minValue) * 100 ) / (maxValue - minValue);

        Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getReportList/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => reportListData = json.reportList)
          .then(() => {
            if (farm.farmVisibility) {
              for (let i = 0; i < reportListData.length; i++){
                if (reportListData[i].name == "Текущие лечения") {
                  const value = {
                    farmId: farm.farmId,
                    reportId: reportListData[i].reportId
                  }

                  reportsIds.push(value);

                  farmsArray.push(farm.farmName);

                  break;
                }   
              }
            }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          Promise.all(reportsIds.map(report =>
            fetch("https://api.maystar.polyefir-mobile.by/getReportByFarm/" + report.farmId + "/" + report.reportId)
            .then((response) => response.json())
            .then((json) => {
              healthColumnsData = json.reportColumns;
              healthRowsData = json.reportRows;
            })
            .then(() => {
              let diseaseIndex = healthColumnsData.indexOf("Заболевание"); 

              sickCount = 0;

              healthRowsData.forEach(element => {
                 if ((element.reportRowValues[diseaseIndex] == "Артрит") || (element.reportRowValues[diseaseIndex] == "Вывих") || (element.reportRowValues[diseaseIndex] == "Ламинит") ||
                      (element.reportRowValues[diseaseIndex] == "Перелом") || (element.reportRowValues[diseaseIndex] == "Повреждения копыта") || (element.reportRowValues[diseaseIndex] == "Тенденит")) {
                        sickCount = sickCount + 1;
                } 
              });  

              sickArray.push(sickCount);
            })
            .catch((error) => console.log(error))
          )) 
          .then(() => {
            Promise.all(reportsIds.map(report =>
              fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + report.farmId)
              .then((response) => response.json())
              .then((json) => statusesData = json.statusValues)
              .then(() => {
                totalCount = 0;

                statusesData.forEach((element, index) => {
                  totalCount = totalCount + parseInt(element.replace(/ /g,''));                    
                });    

                totalArray.push(totalCount);
              })
              .catch((error) => console.log(error))
            )) 
            .then(() => {
              totalArray.forEach((element, index) => {
                if (element != 0) {
                  baseSeriesArray.push((sickArray[index] / element * 100).toFixed(2));
                  seriesArray.push(valueToPercent((sickArray[index] / element * 100).toFixed(2)));
                }                    
              });

              setBaseSeries(baseSeriesArray);
              setSeries(seriesArray);
              setFarmsSeries(farmsArray);
  
              chartRef.current.chart.ctx.updateOptions({
                ...options,
                labels: farmsArray,
                plotOptions: {
                  radialBar: { 
                    dataLabels: {
                      value: {
                        formatter: (val) => ( val * (maxValue - minValue) ) / 100 + minValue + "%"
                      },
                      total: {
                        formatter: function (w) {
                          return "Менее " + parseFloat(manager.lamenessGoal) + "%"
                        }
                      }
                    }
                  }
                }
              });        
            })         
          })          
        })
      })
      .catch((error) => console.log(error));  
    }, [props.farms]);

    return (
        <div class="card h-100">
            <div class="card-header d-flex align-items-center justify-content-between pb-0">
            <div class="card-title mb-0">
                <h5 class="m-0 me-2 fw-semibold-2">Хромота</h5>
            </div>
            <button
                class="btn p-0"
                type="button"
                id="orderStatistics"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={handleClickOpen}>
                <i class="fa-solid fa-gear icon-base"></i>
              </button>
              <Dialog open={open} onClose={handleClose}>
              <DialogTitle style={{ fontWeight: 'bold' }}>Хромота</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="goal"
                  label="Целевое значение"
                  fullWidth
                  variant="standard"
                  value={newGoal}
                  error={errorText.length === 0 ? false : true }
                  helperText={errorText}
                  onChange={changeGoal}
                />
              </DialogContent>
              <DialogActions>
                <Button style={{ fontWeight: 'bold' }} onClick={handleClose}>Отмена</Button>
                <Button style={{ fontWeight: 'bold' }} onClick={handleSave}>Сохранить</Button>
              </DialogActions>
            </Dialog>
            </div>
            <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
                <Chart
                    ref={chartRef}
                    options={options}
                    series={series}
                    width={400}
                    height={400}
                    type="radialBar"
                />
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
            </div>
            </div>
        </div>
    );
  }