import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function TotalCowYield(props) {
    let [average, setAverage] = useState(0);

    useEffect(() => { 
        let milkInfoData = null;

        let average = 0;
        let count = 0;

        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getMilkInfo/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => milkInfoData = json.milkInfo)
            .then(() => {
                if (farm.farmVisibility) {
                    let yieldSumSession1 = 0;
                    let yieldSumSession2 = 0;
                    let yieldSumSession3 = 0;
                    let cowCountSumSession1 = 0;
                    let cowCountSumSession2 = 0;
                    let cowCountSumSession3 = 0;
                    let averageYield = 0;

                    milkInfoData.forEach(element => {
                      if (parseInt(element.session) == 1) {
                        yieldSumSession1 = yieldSumSession1 + parseFloat(element.yield);
                        cowCountSumSession1 = cowCountSumSession1 + parseFloat(element.cowCount);
                      } else if (parseInt(element.session) == 2) {
                        yieldSumSession2 = yieldSumSession2 + parseFloat(element.yield);
                        cowCountSumSession2 = cowCountSumSession2 + parseFloat(element.cowCount);
                      } else if (parseInt(element.session) == 3) {
                        yieldSumSession3 = yieldSumSession3 + parseFloat(element.yield);
                        cowCountSumSession3 = cowCountSumSession3 + parseFloat(element.cowCount);
                      }
                    });

                    if (cowCountSumSession1 != 0) {
                      averageYield = yieldSumSession1 / cowCountSumSession1;
                    }

                    if (cowCountSumSession2 != 0) {
                      averageYield = averageYield + yieldSumSession2 / cowCountSumSession2;
                    }

                    if (cowCountSumSession3 != 0) {
                      averageYield = averageYield + yieldSumSession3 / cowCountSumSession3;
                    }

                    averageYield = averageYield / 1000;

                    average = average + averageYield;
    
                    count = count + 1;
                }            
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {
            if (count != 0) {
              average = average / count;
            }
            
            setAverage(average);        
          })    
    }, [props.farms]);

    return (
      <span class="fw-bold">{average.toFixed(2)}</span>
    );
  }