import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function TotalConductivity(props) {
    let [average, setAverage] = useState(0);

    useEffect(() => { 
        let milkingEfficiencyData = null;
        let milkingEfficiencyValueData = null;

        let average = 0;
        let count = 0;

        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => { 
                milkingEfficiencyData = json.milkingEfficiency;
                milkingEfficiencyValueData = json.milkingEfficiencyValue;
            })
            .then(() => {
                if (farm.farmVisibility) {
                    if (milkingEfficiencyValueData[6] != undefined) {
                      average = average + parseInt(milkingEfficiencyValueData[6]);
    
                      count = count + 1;
                    }
                }            
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {
            if (count != 0) {
              average = average / count;
            }
           
            setAverage(average);         
          })    
    }, [props.farms]);

    return (
      <span class="fw-bold">{average.toFixed(0)}</span>
    );
  }