import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1',
      calf: '#808080',
      openHeifer: '#8381ff',
      insemHeifer: '#90ff00',
      uncalved: '#57ffff',
      involution: '#f9b9a1',
      open: '#7e0cff',
      insem: '#25803d',
      incalf: '#3ba7ff',
      barren: '#f5009d',
      dryoff: '#a28200',
      cull: '#f9bf00'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function HealthChart(props) {
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
        chart: {
            height: 230,
            width: 190,
            type: 'donut'
          },
          labels: ['Копыта и ноги', 'Инфекционные болезни', 'Мастит', 'Воспроизводство', 'Болезнь молодняка', 'Общие', 'Здоровые'],
          colors: [config.colors.calf, config.colors.openHeifer, config.colors.insemHeifer, config.colors.uncalved, config.colors.involution, config.colors.open, config.colors.insem],
          stroke: {
            width: 5,
            // colors: cardColor
          },
          dataLabels: {
            enabled: false,
            formatter: function (val, opt) {
              return parseInt(val);
            }
          },
          legend: {
            show: false
          },
          grid: {
            padding: {
              top: 0,
              bottom: 0,
              right: 15
            }
          },
          plotOptions: {
            pie: {
              donut: {
                size: '75%',
                labels: {
                  show: true,
                  value: {
                    fontSize: '1.5rem',
                    fontFamily: 'Public Sans',
                    color: headingColor,
                    offsetY: -15
                  },
                  name: {
                    offsetY: 20,
                    fontFamily: 'Public Sans'
                  },
                  total: {
                    show: true,
                    fontSize: '0.8125rem',
                    fontWeight: '700',
                    color: axisColor,
                    label: 'Всего коров'
                  }
                }
              }
            }
          }
    });

    useEffect(() => { 
      let reportListData = null;
      let healthColumnsData = null;
      let healthRowsData = null;
      let statusesData = null;

      let reportsIds = [];

      let seriesArray = [0, 0, 0, 0, 0, 0, 0];

      let totalCount = 0;

      Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getReportList/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => reportListData = json.reportList)
          .then(() => {
            if (farm.farmVisibility) {
              for (let i = 0; i < reportListData.length; i++){
                if (reportListData[i].name == "Текущие лечения") {
                  const value = {
                    farmId: farm.farmId,
                    reportId: reportListData[i].reportId
                  }

                  reportsIds.push(value);

                  break;
                }   
              }
            }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          Promise.all(reportsIds.map(report =>
            fetch("https://api.maystar.polyefir-mobile.by/getReportByFarm/" + report.farmId + "/" + report.reportId)
            .then((response) => response.json())
            .then((json) => {
              healthColumnsData = json.reportColumns;
              healthRowsData = json.reportRows;
            })
            .then(() => {
              let diseaseIndex = healthColumnsData.indexOf("Заболевание"); 

              healthRowsData.forEach(element => {
                if ((element.reportRowValues[diseaseIndex] == "Артрит") || (element.reportRowValues[diseaseIndex] == "Вывих") || (element.reportRowValues[diseaseIndex] == "Ламинит") ||
                    (element.reportRowValues[diseaseIndex] == "Перелом") || (element.reportRowValues[diseaseIndex] == "Повреждения копыта") || (element.reportRowValues[diseaseIndex] == "Тенденит")) {
                  seriesArray[0] = seriesArray[0] + 1;  
                } else if ((element.reportRowValues[diseaseIndex] == "Актиномикоз") || (element.reportRowValues[diseaseIndex] == "Анаплазмоз") || (element.reportRowValues[diseaseIndex] == "Лептоспироз") ||
                            (element.reportRowValues[diseaseIndex] == "Лейкемия") || (element.reportRowValues[diseaseIndex] == "Др.инф. заболевания")) {
                  seriesArray[1] = seriesArray[1] + 1;
                } else if ((element.reportRowValues[diseaseIndex] == "Кровавое молоко") || (element.reportRowValues[diseaseIndex] == "Клинический мастит") || (element.reportRowValues[diseaseIndex] == "Отек вымени") ||
                            (element.reportRowValues[diseaseIndex] == "Рана соска") || (element.reportRowValues[diseaseIndex] == "Мастит 2")) {
                  seriesArray[2] = seriesArray[2] + 1;
                } else if ((element.reportRowValues[diseaseIndex] == "Аборт") || (element.reportRowValues[diseaseIndex] == "Др.проблемы матки") || (element.reportRowValues[diseaseIndex] == "Киста") ||
                            (element.reportRowValues[diseaseIndex] == "Нет охоты") || (element.reportRowValues[diseaseIndex] == "Эндометрит") || (element.reportRowValues[diseaseIndex] == "Заболевание яичников")) {
                  seriesArray[3] = seriesArray[3] + 1;
                } else if ((element.reportRowValues[diseaseIndex] == "Врожденные аномалии") || (element.reportRowValues[diseaseIndex] == "Грыжа") || (element.reportRowValues[diseaseIndex] == "Др.болезни") ||
                            (element.reportRowValues[diseaseIndex] == "Пневмония") || (element.reportRowValues[diseaseIndex] == "Омфалит")) {
                  seriesArray[4] = seriesArray[4] + 1;
                } else if (element.reportRowValues[diseaseIndex] == "Заболевание") {
                  seriesArray[5] = seriesArray[5] + 1;
                }   
              });    
            })
            .catch((error) => console.log(error))
          )) 
          .then(() => {
            Promise.all(reportsIds.map(report =>
              fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + report.farmId)
              .then((response) => response.json())
              .then((json) => statusesData = json.statusValues)
              .then(() => {
                let cowsCount = 0;

                statusesData.forEach((element, index) => {
                  cowsCount = cowsCount + parseInt(element.replace(/ /g,''));                    
                });
              })
              .catch((error) => console.log(error))
            )) 
            .then(() => {
              setSeries(seriesArray);
  
              seriesArray.forEach(element => {
                totalCount = totalCount + element;
              });
  
              if (totalCount != 0) {
                options = {
                  ...options,
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          value: {
                            formatter: function (val) {                           
                              return ((parseInt(val) * 100) / totalCount).toFixed(2) + "%";
                            }
                          },
                        }
                      }
                    }
                  }
                };
  
                setOptions(options) 
              }        
            })         
          })          
        }) 
    }, [props.farms]);

    return (
        <div class="card h-100">
            <div class="card-header d-flex align-items-center justify-content-between pb-0">
              <div class="card-title mb-0">
                <h5 class="m-0 me-2 fw-semibold-2">Статистика по заболеваниям</h5>
                <small class="text-muted fw-semibold-2">Суммарная информация по всем фермам</small>
              </div>
            </div>
            <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
                <Chart
                    options={options}
                    series={series}
                    width={190}
                    height={230}
                    type="donut"
                />
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
            </div>
            <ul class="p-0 m-0">
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-calf"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Копыта и ноги</h6>
                    <small class="text-muted"></small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[0]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-openHeifer"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Инфекционные болезни</h6>
                    <small class="text-muted"></small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[1]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-insemHeifer"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Мастит</h6>
                    <small class="text-muted"></small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[2]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-uncalved"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Воспроизводство</h6>
                    <small class="text-muted"></small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[3]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-involution"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Болезнь молодняка</h6>
                    <small class="text-muted"></small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[4]}</small>
                    </div>
                </div>
                </li>
                <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-open"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Общие</h6>
                    <small class="text-muted"></small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[5]}</small>
                    </div>
                </div>
                </li>
                {/* <li class="d-flex mb-2-2 pb-1">
                <div class="avatar flex-shrink-0 me-3">
                  <i class="fa-solid fa-cow icon-insem"></i>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                    <div class="me-2">
                    <h6 class="mb-0 fw-semibold-2">Здоровые</h6>
                    <small class="text-muted"></small>
                    </div>
                    <div class="user-progress">
                    <small class="fw-semibold">{series[6]}</small>
                    </div>
                </div>
                </li> */}
            </ul>
            </div>
        </div>
    );
  }