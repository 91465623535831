import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { 
  TableCell
} from '@material-ui/core';

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
      border: '0 solid #d9dee3',
      borderRadius: '0.5rem',
      boxShadow: '0 2px 6px 0 rgba(67, 89, 113, 0.12)'
  },
  tableHeaderCell: {
      textAlign: 'left',
      fontWeight: 'bold',
      backgroundColor: '#0064d7',
      color: theme.palette.getContrastText('#0064d7')
  },
  tableCell: {
      textAlign: 'left',
      color: '#566a7f'
  },
  avatar: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.getContrastText(theme.palette.primary.light)
  },
  name: {
      fontWeight: 'bold',
      color: '#566a7f'
  },
  status: {
      fontWeight: 'bold',
      fontSize: '0.75rem',
      color: 'white',
      backgroundColor: 'grey',
      borderRadius: 8,
      padding: '3px 10px',
      display: 'inline-block'
  }
}));

export default function TableCellCowYield(props) {
    const classes = useStyles();  

    let [averageYield, setAverageYield] = useState(null);

    useEffect(() => { 
        let milkInfoData = null;

        fetch("https://api.maystar.polyefir-mobile.by/getMilkInfo/" + props.farmId)
        .then((response) => response.json())
        .then((json) => milkInfoData = json.milkInfo)
        .then(() => {
          let yieldSumSession1 = 0;
          let yieldSumSession2 = 0;
          let yieldSumSession3 = 0;
          let cowCountSumSession1 = 0;
          let cowCountSumSession2 = 0;
          let cowCountSumSession3 = 0;
          let averageYield = 0;

          milkInfoData.forEach(element => {
            if (parseInt(element.session) == 1) {
              yieldSumSession1 = yieldSumSession1 + parseFloat(element.yield);
              cowCountSumSession1 = cowCountSumSession1 + parseFloat(element.cowCount);
            } else if (parseInt(element.session) == 2) {
              yieldSumSession2 = yieldSumSession2 + parseFloat(element.yield);
              cowCountSumSession2 = cowCountSumSession2 + parseFloat(element.cowCount);
            } else if (parseInt(element.session) == 3) {
              yieldSumSession3 = yieldSumSession3 + parseFloat(element.yield);
              cowCountSumSession3 = cowCountSumSession3 + parseFloat(element.cowCount);
            }
          });

          if (cowCountSumSession1 != 0) {
            averageYield = yieldSumSession1 / cowCountSumSession1;
          }

          if (cowCountSumSession2 != 0) {
            averageYield = averageYield + yieldSumSession2 / cowCountSumSession2;
          }

          if (cowCountSumSession3 != 0) {
            averageYield = averageYield + yieldSumSession3 / cowCountSumSession3;
          }

          averageYield = averageYield / 1000;

          props.farms.find(farm => farm.farmId == props.farmId).cowYield = averageYield;

          setAverageYield(averageYield.toFixed(2));          
        })
        .catch((error) => console.log(error))             
    }, [props.farms]);

    return (
      <TableCell className={classes.tableCell} width="10%">{averageYield}</TableCell>
    );
  }