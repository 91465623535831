import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function TotalOperatingTime(props) {
    let [farmName, setFarmName] = useState(null);
    let [date, setDate] = useState(null);
    let [days, setDays] = useState(0);

    useEffect(() => { 
        let operatingTimeData = null;

        let minDate = {
          name: "",
          days: 9999 
        };

        Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getOperatingTime/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => operatingTimeData = json.operatingTimeInfo)
          .then(() => {
              if (farm.farmVisibility) {               
                  let maxValue = -1;
                  
                  operatingTimeData.forEach((element, index) => {   
                    if (parseFloat(element.sumFlushDuration.replace(/,/g, '.')) > maxValue) {
                      maxValue = parseFloat(element.sumFlushDuration.replace(/,/g, '.')); 
                    }
                  });
                  
                  if ((1900 - maxValue) < minDate.days) {
                    minDate.name = farm.farmName;
                    minDate.days = (1900 - maxValue);
                  }
              }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          setFarmName(minDate.name);
          var date = new Date();
          setDate(date.setDate(date.getDate() + parseInt((minDate.days / 24).toFixed(0))));
          setDays(minDate.days);
        })    
  }, [props.farms]);

    return (
      <div>
        {(days < 0) && (  
          <p class="fw-semibold mb-3-1">
            Ферма с просроченной датой ТО {farmName}
            <br/>ТО просрочено. Дата {((new Date(date).getDate() < 10) ? "0" + (new Date(date).getDate()) : new Date(date).getDate()) + "." + ((new Date(date).getMonth() + 1 < 10) ? "0" + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1) + "." + new Date(date).getFullYear()} 
            <br/>Дней прошло {Math.abs(days / 24).toFixed(0)} д
          </p>
        )} 
        {(days > 0) && (  
          <p class="fw-semibold mb-3-1">
            Ферма с ближайшей датой ТО {farmName}
            <br/>Ближайшая дата ТО {((new Date(date).getDate() < 10) ? "0" + (new Date(date).getDate()) : new Date(date).getDate()) + "." + ((new Date(date).getMonth() + 1 < 10) ? "0" + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1) + "." + new Date(date).getFullYear()} 
            <br/>Осталось дней {(days / 24).toFixed(0)} д
          </p> 
        )}
        {(days == 0) && (  
          <p class="fw-semibold mb-3-1">
            Ферма с ближайшей датой ТО {farmName}
            <br/>Ближайшая дата ТО {((new Date(date).getDate() < 10) ? "0" + (new Date(date).getDate()) : new Date(date).getDate()) + "." + ((new Date(date).getMonth() + 1 < 10) ? "0" + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1) + "." + new Date(date).getFullYear()} 
            <br/>Сегодня дата ТО
          </p> 
        )}
      </div>
    );
  }