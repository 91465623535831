import React, { Component } from 'react'; 
import page404 from './page404.gif';
import './App.css';
import { useEffect } from "react";

const NotFoundRoute = props => {   
    useEffect(() => { 
        document.title = "Страница не найдена";  
    });

    return (
        <div class="container-404">
            <img src={page404} class="img-full-screen" alt="404" />
            <div class="top-center">
                <h1 class="h1-custom">404</h1>
                <h2 class="h2-custom">Такой страницы не существует</h2>
                <h3 class="h3-custom">Вернитесь на<a href="/" class="link-bolder">&nbsp;главную&nbsp;</a>для продолжения работы</h3>
            </div>
        </div>
    );
}
  
export default NotFoundRoute;  