import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { 
  TableCell
} from '@material-ui/core';


let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
    },
    tableContainer: {
        border: '0 solid #d9dee3',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 6px 0 rgba(67, 89, 113, 0.12)'
    },
    tableHeaderCell: {
        textAlign: 'left',
        fontWeight: 'bold',
        backgroundColor: '#0064d7',
        color: theme.palette.getContrastText('#0064d7')
    },
    tableCell: {
        textAlign: 'left',
        color: '#566a7f'
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light)
    },
    name: {
        fontWeight: 'bold',
        color: '#566a7f'
    },
    status: {
        fontWeight: 'bold',
        fontSize: '0.75rem',
        color: 'white',
        backgroundColor: 'grey',
        borderRadius: 8,
        padding: '3px 10px',
        display: 'inline-block'
    }
  }));

export default function TableCellRating(props) {
    const classes = useStyles();  

    let [rating, setRating] = useState(null);
  
    useEffect(() => { 
        let farm = props.farmsRatings.find(farm => farm.farmId == props.farmId);

        if (farm != undefined) {
            let farmRating = farm.farmRating;
            
            props.farms.find(farm => farm.farmId == props.farmId).rating = farmRating.toFixed(0);
    
            setRating(farmRating.toFixed(0)); 
        }      
    }, [props.farms, props.farmsRatings]);

    return (
        <TableCell className={classes.tableCell} width="10%">{rating}</TableCell>
    );
  }