import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function TotalHerdYield(props) {
    let [yieldSum, setYieldSum] = useState(0);

    useEffect(() => { 
        let milkInfoData = null;

        let sum = 0;

        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getMilkInfo/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => milkInfoData = json.milkInfo)
            .then(() => {
                if (farm.farmVisibility) {
                    let yieldSum = 0;

                    milkInfoData.forEach(element => {
                        yieldSum = yieldSum + parseFloat(element.yield);
                    });

                    yieldSum = yieldSum / 1000000;

                    sum = sum + yieldSum;
                }            
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {
            setYieldSum(sum);           
          })    
    }, [props.farms]);

    return (
      <span class="fw-bold">{yieldSum.toFixed(2)}</span>
    );
  }