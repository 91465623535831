import React, { Component } from 'react'; 
import useScript from './hooks/useScript'; 
import Chart from "react-apexcharts";
import './assets/vendor/js/bootstrap.js';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = props => {   
    useScript('./assets/vendor/libs/jquery/jquery.js');
    useScript('./assets/vendor/libs/popper/popper.js');
    useScript('./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js');
    useScript('./assets/vendor/js/menu.js');
    useScript('./assets/vendor/libs/apex-charts/apexcharts.js');
    useScript('./assets/js/main.js');
    useScript('./assets/js/dashboards-analytics.js');
    useScript('https://buttons.github.io/buttons.js');

    useEffect(() => { 
      document.title = "Вход в систему"; 
    });

    const navigate = useNavigate();

    const [login, setLogin] = useState("");

    const [password, setPassword] = useState("");

    const [errorText, setErrorText] = useState("");

    async function postData(url = '', data = {}) {
      const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(data) 
      });
      return response.json(); 
    }

    const changeLogin = (e) => {
      let input = e.target.value;

      setLogin(input);
    };

    const changePassword = (e) => {
      let input = e.target.value;

      setPassword(input);
    };

    const handleLogin = () => {
      let manager = null;

      postData("https://api.maystar.polyefir-mobile.by/loginManager", { login: login, password: password })
      .then((response) => manager = response)
      .then(() => {
          if (manager.login == null) { 
            setErrorText("Неверное имя пользователя или пароль");
          } else {
            localStorage.setItem("login", manager.login);
            localStorage.setItem("password", manager.password);
            navigate("/");
          }      
      })
      .catch((error) => console.log(error));
    };

    return (
      <div className="login-center">
        <div className="card login-card">
          <div className="app-brand-text demo menu-text fw-bolder">
            Вход в систему
          </div>
          <TextField
              margin="dense"
              id="login"
              label="Логин"
              variant="standard"
              fullWidth
              value={login}
              error={errorText.length === 0 ? false : true }
              helperText={errorText}
              onChange={changeLogin}
            />
            <TextField
              margin="dense"
              id="password"
              type="password"
              label="Пароль"
              variant="standard"
              fullWidth
              value={password}
              error={errorText.length === 0 ? false : true }
              helperText={errorText}
              onChange={changePassword}
            />
            <Button variant="contained" style={{ fontWeight: 'bold' }} sx={{mt: 4, mb: 1, ml: 10, mr: 10}} onClick={handleLogin}>Войти</Button>
        </div>
      </div>
    );
}
  
export default Login;  