import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"];

const currentDate = new Date();

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

const maxValue = 6000;
const minValue = 4000;
const valueToPercent = (val) => ( (val - minValue) * 100 ) / (maxValue - minValue);

export default function OperatingTime(props) {
  let [series, setSeries] = useState([]);
  let [options, setOptions] = useState({
      chart: {
          height: 300,
          stacked: true,
          type: 'bar',
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 20,
            startingShape: 'rounded',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1,
          lineCap: 'round',
          colors: [cardColor]
        },
        colors: ['#696CFF', '#696CFF1F'],
        legend: {
          show: true,
          horizontalAlign: 'left',
          position: 'top',
          markers: {
            height: 8,
            width: 8,
            radius: 12,
            offsetX: -3
          },
          labels: {
            colors: axisColor
          },
          itemMargin: {
            horizontal: 10
          }
        },
        grid: {
          borderColor: borderColor,
          padding: {
            top: 0,
            bottom: -8,
            left: 0,
            right: 20
          }
        },
        xaxis: {
          categories: [],
          min: 0,
          labels: {
            style: {
              fontSize: '13px',
              colors: axisColor
            },
            formatter: function (val) {
              return parseFloat(val).toFixed(0) + " ч";
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: '13px',
              colors: axisColor
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return parseFloat(val).toFixed(0) + " ч"
            }
          }
        },
        responsive: [
          {
            breakpoint: 1700,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '32%'
                }
              }
            }
          },
          {
            breakpoint: 1580,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '35%'
                }
              }
            }
          },
          {
            breakpoint: 1440,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '42%'
                }
              }
            }
          },
          {
            breakpoint: 1300,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '48%'
                }
              }
            }
          },
          {
            breakpoint: 1200,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '40%'
                }
              }
            }
          },
          {
            breakpoint: 1040,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 11,
                  columnWidth: '48%'
                }
              }
            }
          },
          {
            breakpoint: 991,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '30%'
                }
              }
            }
          },
          {
            breakpoint: 840,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '35%'
                }
              }
            }
          },
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '28%'
                }
              }
            }
          },
          {
            breakpoint: 640,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '32%'
                }
              }
            }
          },
          {
            breakpoint: 576,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '37%'
                }
              }
            }
          },
          {
            breakpoint: 480,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '45%'
                }
              }
            }
          },
          {
            breakpoint: 420,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '52%'
                }
              }
            }
          },
          {
            breakpoint: 380,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '60%'
                }
              }
            }
          }
        ],
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        }
    });   

    let [chartHeight, setChartHeight] = useState(0);

    useEffect(() => { 
        let operatingTimeData = null;

        let seriesArray = [];
        let farmsArray = [];

        let currentSerie = {
          name: "Текущая наработка",
          data: [] 
        }; 

        let currentSerieEmpty = {
          name: "Осталось до ТО",
          data: [] 
        };

        Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getOperatingTime/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => operatingTimeData = json.operatingTimeInfo)
          .then(() => {
              if (farm.farmVisibility) {               
                  let maxValue = -1;

                  operatingTimeData.forEach((element, index) => { 
                    if (parseFloat(element.sumFlushDuration.replace(/,/g, '.')) > maxValue) {
                      maxValue = parseFloat(element.sumFlushDuration.replace(/,/g, '.')); 
                    }
                  });

                  currentSerie.data.push(maxValue.toFixed(2));
                  currentSerieEmpty.data.push((4000 - maxValue).toFixed(2));
                  
                  farmsArray.push(farm.farmName);
              }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          seriesArray.push(currentSerie); 
          seriesArray.push(currentSerieEmpty); 

          setSeries(seriesArray); 

          setChartHeight(100 + farmsArray.length * 50);     

          options = {
              ...options,
              xaxis: { 
                categories: farmsArray
              }
          };

          setOptions(options)
        })    
  }, [props.farms]);

    return (
      <div class="card h-100">        
        <h5 class="card-header m-0 me-2 pb-3 fw-semibold">Наработка</h5>
        <Chart options={options} series={series} type="bar" height={chartHeight} />  
      </div>
    );
  }