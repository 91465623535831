import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"];

const currentDate = new Date();

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

const maxValue = 5000;
const minValue = 0;
const valueToPercent = (val) => ( (val - minValue) * 100 ) / (maxValue - minValue);

export default function YieldChart(props) {
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
        chart: {
            height: 300,
            stacked: false,
            type: 'bar',
            toolbar: { show: false }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 20,
              startingShape: 'rounded',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 1,
            lineCap: 'round',
            colors: [cardColor]
          },
          legend: {
            show: true,
            horizontalAlign: 'left',
            position: 'top',
            markers: {
              height: 8,
              width: 8,
              radius: 12,
              offsetX: -3
            },
            labels: {
              colors: axisColor
            },
            itemMargin: {
              horizontal: 10
            }
          },
          grid: {
            borderColor: borderColor,
            padding: {
              top: 0,
              bottom: -8,
              left: 0,
              right: 20
            }
          },
          xaxis: {
            categories: [""],
            labels: {
              style: {
                fontSize: '13px',
                colors: axisColor
              },
              formatter: function (val) {
                return val + " кг";
              }
            },
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            labels: {
              show: false,
              style: {
                fontSize: '13px',
                colors: axisColor
              }
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return parseFloat(val).toFixed(0) + " кг"
              }
            }
          },
          responsive: [
            {
              breakpoint: 1700,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '32%'
                  }
                }
              }
            },
            {
              breakpoint: 1580,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '35%'
                  }
                }
              }
            },
            {
              breakpoint: 1440,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '42%'
                  }
                }
              }
            },
            {
              breakpoint: 1300,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '48%'
                  }
                }
              }
            },
            {
              breakpoint: 1200,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '40%'
                  }
                }
              }
            },
            {
              breakpoint: 1040,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 11,
                    columnWidth: '48%'
                  }
                }
              }
            },
            {
              breakpoint: 991,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '30%'
                  }
                }
              }
            },
            {
              breakpoint: 840,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '35%'
                  }
                }
              }
            },
            {
              breakpoint: 768,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '28%'
                  }
                }
              }
            },
            {
              breakpoint: 640,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '32%'
                  }
                }
              }
            },
            {
              breakpoint: 576,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '37%'
                  }
                }
              }
            },
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '45%'
                  }
                }
              }
            },
            {
              breakpoint: 420,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '52%'
                  }
                }
              }
            },
            {
              breakpoint: 380,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '60%'
                  }
                }
              }
            }
          ],
          states: {
            hover: {
              filter: {
                type: 'none'
              }
            },
            active: {
              filter: {
                type: 'none'
              }
            }
          }
    });  

    let [averageConfig, setAverageConfig] = useState({
      series: [],
      options: {
          chart: {
              width: 60,
              height: 60,
              type: 'radialBar'
          },
          plotOptions: {
              radialBar: {
              startAngle: 0,
              endAngle: 360,
              strokeWidth: '8',
              hollow: {
                  margin: 2,
                  size: '45%'
              },
              track: {
                  strokeWidth: '50%',
                  background: borderColor
              },
              dataLabels: {
                  show: true,
                  name: {
                  show: false
                  },
                  value: {
                  formatter: (val) => ( val * (maxValue - minValue) ) / 100 + minValue,
                  offsetY: 5,
                  color: '#697a8d',
                  fontSize: '13px',
                  show: true
                  }
              }
              }
          },
          fill: {
              type: 'solid',
              colors: config.colors.primary
          },
          stroke: {
              lineCap: 'round'
          },
          grid: {
              padding: {
              top: -10,
              bottom: -15,
              left: -10,
              right: -10
              }
          },
          states: {
              hover: {
              filter: {
                  type: 'none'
              }
              },
              active: {
              filter: {
                  type: 'none'
              }
              }
          }     
      }  
    });

    let [bestFarm, setBestFarm] = useState({
        bestFarmName: "",
        bestFarmValue: 0,
        bestFarmDeviation: 0,
    });

    let [worstFarm, setWorstFarm] = useState({
        worstFarmName: "",
        worstFarmValue: 0,
        worstFarmDeviation: 0,
    });

    let [chartHeight, setChartHeight] = useState(0);

    let [yieldSum, setYieldSum] = useState(0);

    useEffect(() => { 
        let milkingEfficiencyData = null;
        let milkingEfficiencyValueData = null;

        let seriesArray = [];

        let sum = 0;
        let average = 0;
        let count = 0;

        let maxFarmName = null;
        let minFarmName = null;

        let maxValue = -1;
        let minValue = 99999;

        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => { 
                milkingEfficiencyData = json.milkingEfficiency;
                milkingEfficiencyValueData = json.milkingEfficiencyValue;
            })
            .then(() => {
                if (farm.farmVisibility) {
                    let currentSerie = {
                        name: null,
                        data: null 
                    }; 

                    currentSerie.name = farm.farmName;

                    if (parseFloat(milkingEfficiencyValueData[5]) > maxValue) {
                        maxValue = parseFloat(milkingEfficiencyValueData[5]); 
                        maxFarmName = farm.farmName;
                    }

                    if (parseFloat(milkingEfficiencyValueData[5]) < minValue) {
                        minValue = parseFloat(milkingEfficiencyValueData[5]); 
                        minFarmName = farm.farmName;
                    }

                    currentSerie.data = [parseFloat(milkingEfficiencyValueData[5]).toFixed(2)];

                    sum = sum + parseFloat(milkingEfficiencyValueData[5]);

                    average = average + parseFloat(milkingEfficiencyValueData[5]);
    
                    count = count + 1;

                    seriesArray.push(currentSerie); 
                }            
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {
            setSeries(seriesArray); 

            setChartHeight(100 + seriesArray.length * 50);

            setYieldSum(sum);
         
            if (count != 0) {
                average = average / count;
            }

            averageConfig = {
                ...averageConfig,
                series: [valueToPercent(average).toFixed(0)]
            };
            
            setAverageConfig(averageConfig);

            if (props.farms.length == 1) {
                let maxDeviation = 0;

                if (average != 0) {
                    maxDeviation = ((maxValue - average) / average) * 100;   
                }

                bestFarm = {
                    bestFarmName: maxFarmName,
                    bestFarmValue: maxValue,
                    bestFarmDeviation: maxDeviation,
                };
                
                setBestFarm(bestFarm);

                worstFarm = {
                    worstFarmName: "-",
                    worstFarmValue: 0,
                    worstFarmDeviation: 0,
                };
                
                setWorstFarm(worstFarm);
            } else if (props.farms.length > 1) {
                let maxDeviation = 0;

                if (average != 0) {
                    maxDeviation = ((maxValue - average) / average) * 100;   
                }

                bestFarm = {
                    bestFarmName: maxFarmName,
                    bestFarmValue: maxValue,
                    bestFarmDeviation: maxDeviation,
                };
                
                setBestFarm(bestFarm);

                let minDeviation = 0;

                if (average != 0) {
                    minDeviation = ((minValue - average) / average) * 100;   
                }

                worstFarm = {
                    worstFarmName: minFarmName,
                    worstFarmValue: minValue,
                    worstFarmDeviation: minDeviation,
                };
                
                setWorstFarm(worstFarm);
            }           
          })    
    }, [props.farms]);

    return (
        <div class="tab-pane fade show active" id="yieldTab" role="tabpanel">
            <div class="d-flex p-4 pt-3 justify-content-between">
                <div class="d-flex">
                    <div class="avatar flex-shrink-0 me-3">
                        <i class="fa-solid fa-thumbs-up icon-best"></i>         
                    </div>
                    <div>
                        <small class="text-muted d-block">{bestFarm.bestFarmName}</small>
                        <div class="d-flex align-items-center">
                            <h6 class="mb-0 me-1">{bestFarm.bestFarmValue.toFixed(2)}</h6>
                            <small class="text-success fw-semibold">
                            <i class="bx bx-chevron-up"></i>
                            {Math.abs(bestFarm.bestFarmDeviation).toFixed(2)}%
                            </small>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="avatar flex-shrink-0 me-3">
                        <i class="fa-solid fa-thumbs-down icon-worst"></i>
                    </div>
                    <div>
                        <small class="text-muted d-block">{worstFarm.worstFarmName}</small>
                        <div class="d-flex align-items-center">
                            <h6 class="mb-0 me-1">{worstFarm.worstFarmValue.toFixed(2)}</h6>
                            <small class="text-danger fw-semibold">
                            <i class="bx bx-chevron-down"></i>
                            {Math.abs(worstFarm.worstFarmDeviation).toFixed(2)}%
                            </small>
                        </div>
                    </div>
                </div>
                <div class="d-flex">                
                </div>
            </div>
            <Chart options={options} series={series} type="bar" height={chartHeight} />
            <div class="d-flex justify-content-center pt-4 gap-2">
                <div class="flex-shrink-0">
                  <Chart options={averageConfig.options} series={averageConfig.series} type="radialBar" width={60} height={60} />
                </div>
                <div>
                <p class="mb-n1 mt-1 fw-semibold-2">Надой за последнюю дойку</p>
                <small class="text-muted fw-semibold-2">Суммарное значение по всем фермам: {yieldSum.toFixed(2)} кг</small>
                </div>
            </div>
        </div>
    );
  }