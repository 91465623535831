import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function MilkCowsChart(props) {
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
      labels: [],
      chart: {
        height: 240,
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
          size: 150,
          offsetY: 10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '55%'
          },
          track: {
            background: cardColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: 15,
              color: headingColor,
              fontSize: '18px',
              fontWeight: '600',
              fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
            },
            value: {
              offsetY: -25,
              color: headingColor,
              fontSize: '22px',
              fontWeight: '500',
              fontFamily: `"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
            }
          }
        }
      },
      colors: [],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.5,
          gradientToColors: [],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.6,
          stops: [30, 70, 100]
        }
      },
      stroke: {
        dashArray: 5
      },
      grid: {
        padding: {
          top: -35,
          bottom: -10
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        },
        active: {
          filter: {
            type: 'none'
          }
        }
      }      
    });  

    let [selectedFarm, setSelectedFarm] = useState(null);

    let [deviation, setDeviation] = useState(null);

    let [analytics, setAnalytics] = useState(null);

    let [forecast, setForecast] = useState(null);

    useEffect(() => { 
        let milkCowsEmptyData = null;
        let milkCowsData = null;

        let isSelectedFarmExist = false;

        let seriesArray = [];

        let previousSum = 0;
        let currentSum = 0;

        let analyticsSum = 0;
        let forecastSum = 0;

        if (props.farms.length == 0) {
          setSelectedFarm(null);
        }
      
        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => {
              milkCowsEmptyData = json.milkingCowEmpty;
              milkCowsData = json.milkingCowValue;
            })
            .then(() => {
                if (farm.farmVisibility) {
                  if ((selectedFarm == null) && (!isSelectedFarmExist)) {
                      setSelectedFarm(farm);

                      isSelectedFarmExist = true;
                  } else if (selectedFarm != null) {
                    if (!props.farms.find(currentFarm => currentFarm.farmId == selectedFarm.farmId)) {
                      setSelectedFarm(farm);       
                    }
                  } 
                }       
                
                previousSum = previousSum + parseInt(milkCowsEmptyData[6]) + parseInt(milkCowsData[6]);
                currentSum = currentSum + parseInt(milkCowsEmptyData[7]) + parseInt(milkCowsData[7]);
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {           
            if (previousSum != 0) {
              setDeviation(((currentSum - previousSum) / previousSum * 100).toFixed(0));   
            } else {
              setDeviation(null);
            }
          })
    }, [props.farms]);

    useEffect(() => { 
      let milkCowsEmptyData = null;
      let milkCowsData = null;

      let previous = 0;
      let current = 0;

      let analyticsSum = 0;
      let forecastSum = 0;

      if (selectedFarm != null) {
        fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + selectedFarm.farmId)
        .then((response) => response.json())
        .then((json) => {
          milkCowsEmptyData = json.milkingCowEmpty;
          milkCowsData = json.milkingCowValue;
        })
        .then(() => {  
          previous = parseInt(milkCowsEmptyData[6]) + parseInt(milkCowsData[6]);
          current = parseInt(milkCowsEmptyData[7]) + parseInt(milkCowsData[7]);

          analyticsSum = parseInt(milkCowsEmptyData[6]) + parseInt(milkCowsData[6]) - parseInt(milkCowsEmptyData[0]) - parseInt(milkCowsData[0]);
          forecastSum = parseInt(milkCowsEmptyData[12]) + parseInt(milkCowsData[12]) - parseInt(milkCowsEmptyData[6]) - parseInt(milkCowsData[6]); 

          if (parseInt(milkCowsEmptyData[0]) != 0) { 
            setSeries([Math.abs((parseInt(milkCowsEmptyData[6]) - parseInt(milkCowsEmptyData[0])) / parseInt(milkCowsEmptyData[0]) * 100).toFixed(0)]);   
            setAnalytics(analyticsSum);
            setForecast(forecastSum);

            if (((parseInt(milkCowsEmptyData[6]) - parseInt(milkCowsEmptyData[0])) / parseInt(milkCowsEmptyData[0]) * 100) > 0) {
              options = {
                ...options,
                labels: ['Рост'],
                colors: ["#71DD37"],
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.5,
                    gradientToColors: ["#71DD37"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.6,
                    stops: [30, 70, 100]
                  }
                }
              };
            } else if (((parseInt(milkCowsEmptyData[6]) - parseInt(milkCowsEmptyData[0])) / parseInt(milkCowsEmptyData[0]) * 100) < 0) {
              options = {
                ...options,
                labels: ['Снижение'],
                colors: ["#FF3E1D"],
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.5,
                    gradientToColors: ["#FF3E1D"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.6,
                    stops: [30, 70, 100]
                  }
                }
              };
            } else if (((parseInt(milkCowsEmptyData[6]) - parseInt(milkCowsEmptyData[0])) / parseInt(milkCowsEmptyData[0]) * 100) == 0) {
              options = {
                ...options,
                labels: ['Без изменений'],
                colors: ["#71DD37"],
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.5,
                    gradientToColors: ["#71DD37"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.6,
                    stops: [30, 70, 100]
                  }
                }
              };
            }

            setOptions(options)
          } else {
            setSeries([]);
            setAnalytics(null);
            setForecast(null);
          }   
        })
        .catch((error) => console.log(error))
      }
  }, [selectedFarm]);

    return (
      <div class="col-md-4">
        <div class="card-body">
        <div class="text-center">
            <div class="dropdown">
            {(selectedFarm != null) && (
              <button
              class="btn btn-sm btn-outline-primary dropdown-toggle"
              type="button"
              id="growthReportId"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >
              {selectedFarm.farmName}
              </button>
            )}
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="growthReportId">
              {props.farms.map(farm => (
                <a class="dropdown-item" href="javascript:void(0);"
                  onClick={() => {
                    setSelectedFarm(farm);                                
                  }}>{farm.farmName} | {farm.farmId}</a>      
              ))}   
            </div>
            </div>
        </div>
        </div>
        <div div class="mt-4">
          <Chart options={options} series={series} type="radialBar" height={240} />
        </div>
        {(deviation != null) && (
          <div>
            {(deviation > 0) && (
              <div class="text-center fw-semibold pt-3 pb-4 mt-5 mb-2">{deviation}% Суммарный рост</div>
            )}
             {(deviation < 0) && (
              <div class="text-center fw-semibold pt-3 pb-4 mt-5 mb-2">{Math.abs(deviation)}% Суммарное снижение</div>
            )}
             {(deviation == 0) && (
              <div class="text-center fw-semibold pt-3 pb-4 mt-5 mb-2">{deviation}% Количество не изменилось</div>
            )}
          </div>
        )}
      </div>
    );
  }