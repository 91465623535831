import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1',
      calf: '#808080',
      openHeifer: '#8381ff',
      insemHeifer: '#90ff00',
      uncalved: '#57ffff',
      involution: '#f9b9a1',
      open: '#7e0cff',
      insem: '#25803d',
      incalf: '#3ba7ff',
      barren: '#f5009d',
      dryoff: '#a28200',
      cull: '#f9bf00'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function GroupsChart(props) {
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
        chart: {
            height: 230,
            width: 190,
            type: 'donut'
          },
          labels: [],
          colors: [],
          stroke: {
            width: 5,
            // colors: cardColor
          },
          dataLabels: {
            enabled: false,
            formatter: function (val, opt) {
              return parseInt(val);
            }
          },
          legend: {
            show: false
          },
          grid: {
            padding: {
              top: 0,
              bottom: 0,
              right: 15
            }
          },
          plotOptions: {
            pie: {
              donut: {
                size: '75%',
                labels: {
                  show: true,
                  value: {
                    fontSize: '1.5rem',
                    fontFamily: 'Public Sans',
                    color: headingColor,
                    offsetY: -15
                  },
                  name: {
                    offsetY: 20,
                    fontFamily: 'Public Sans'
                  },
                  total: {
                    show: true,
                    fontSize: '0.8125rem',
                    fontWeight: '700',
                    color: axisColor,
                    label: 'Всего коров'
                  }
                }
              }
            }
          }
    });

    let [selectedFarm, setSelectedFarm] = useState(null);

    let [names, setNames] = useState([]);

    let [colors, setColors] = useState([]);

    useEffect(() => { 
        let groupNamesData = null;
        let groupColorsData = null;
        let groupValuesData = null;

        let isSelectedFarmExist = false;

        if (props.farms.length == 0) {
          setSelectedFarm(null);
        }

        Promise.all(props.farms.map(farm =>
            fetch("/getStatistics/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => {
              groupNamesData = json.groupNames;
              groupColorsData = json.groupColors;
              groupValuesData = json.groupValues;
            })
            .then(() => {
                if (farm.farmVifarmVisibilitysible) {
                  if ((selectedFarm == null) && (!isSelectedFarmExist)) {
                    setSelectedFarm(farm); 
                    isSelectedFarmExist = true;             
                  } else if (selectedFarm != null) {
                    if (!props.farms.find(currentFarm => currentFarm.farmId == selectedFarm.farmId)) {
                      setSelectedFarm(farm);       
                    }
                  } 
                }            
            })
            .catch((error) => console.log(error))
          ))
    }, [props.farms]);

    useEffect(() => { 
      let groupNamesData = null;
      let groupColorsData = null;
      let groupValuesData = null;

      let seriesArray = [];

      let colorsArray = [];

      let totalCount = 0;

      if (selectedFarm != null) {
        fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + selectedFarm.farmId)
        .then((response) => response.json())
        .then((json) => {
          groupNamesData = json.groupNames;
          groupColorsData = json.groupColors;
          groupValuesData = json.groupValues;
        })
        .then(() => {  
          groupValuesData.forEach((element, index) => {
            seriesArray[index] = parseInt(element.replace(/ /g,''));                    
          });        
        })
        .then(() => {  
          setSeries(seriesArray);

          seriesArray.forEach(element => {
            totalCount = totalCount + element;
          });

          groupNamesData.forEach((element, index) => {
            if (element == "") {
              groupNamesData[index] = "Нет названия";  
            }
          });

          groupColorsData.forEach(element => {
            element >>>= 0;
            var r = element & 0xFF,
                g = (element & 0xFF00) >>> 8,
                b = (element & 0xFF0000) >>> 16,
                a = ( (element & 0xFF000000) >>> 24 ) / 255 ;

            if ((r == g) && (r == b) && (r == 255)) {
              colorsArray.push("#808080");
            } else {
              colorsArray.push("#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1));
            }
          });

          setNames(groupNamesData);

          setColors(colorsArray);

          if (totalCount != 0) {
            options = {
              ...options,
              labels: groupNamesData, 
              colors: colorsArray,
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      value: {
                        formatter: function (val) {                           
                          return ((parseInt(val) * 100) / totalCount).toFixed(2) + "%";
                        }
                      },
                    }
                  }
                }
              }
            };

            setOptions(options); 
          } 
        })
        .catch((error) => console.log(error))
      }
  }, [selectedFarm]);

    function createColor(index){
      const styles = {
        backgroundColor: colors[index] + "1F",
        color: colors[index]
      };

      return styles;
    }

    return (
        <div class="card h-100">
            <div class="card-header d-flex align-items-center justify-content-between pb-0">
            <div class="card-title mb-0">
                <h5 class="m-0 me-2 fw-semibold-2">Статистика по группам</h5>
                {(selectedFarm != null) && (
                  <div>
                    <small class="text-muted fw-semibold-2">Информация по ферме: </small>
                    <h5 class="m-0 me-2 display-inline-block-add fw-semibold-2">{selectedFarm.farmName}</h5>
                  </div>
                )}
            </div>
            <div class="dropdown"> 
                <button
                class="btn p-0"
                type="button"
                id="orderStatistics"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <i class="fa-solid fa-house icon-base"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="orderStatistics">
                  {props.farms.map(farm => (
                    <a class="dropdown-item" href="javascript:void(0);"
                      onClick={() => {
                        setSelectedFarm(farm);                                
                      }}>{farm.farmName} | {farm.farmId}</a>      
                  ))} 
                </div>
            </div>
            </div>
            <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
                <Chart
                    options={options}
                    series={series}
                    width={190}
                    height={230}
                    type="donut"
                />
                <div class="d-flex flex-column align-items-center gap-1">
                </div>
            </div>
            <ul class="p-0 m-0">
                {names.map((element, index) => (
                  <li class="d-flex mb-2-2 pb-1">
                  <div class="avatar flex-shrink-0 me-3">
                    <i class="fa-solid fa-cow icon-base" style={createColor(index)}></i>
                  </div>
                  <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                      <div class="me-2">
                      <h6 class="mb-0 fw-semibold-2">{element}</h6>
                      </div>
                      <div class="user-progress">
                      <small class="fw-semibold">{series[index]}</small>
                      </div>
                  </div>
                  </li>       
                ))}     
            </ul>
            </div>
        </div>
    );
  }