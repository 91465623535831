import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"];

const currentDate = new Date();

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

let maxValueCowYield = 30;
let minValueCowYield = 0;
let valueToPercentCowYield = (val) => ( (val - minValueCowYield) * 100 ) / (maxValueCowYield - minValueCowYield);

export default function CowYieldsMonthlyForecastChart(props) {
    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
            'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify(data) 
        });
        return response.json(); 
    }

    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
        chart: {
              height: 215,
              parentHeightOffset: 0,
              parentWidthOffset: 0,
              toolbar: {
                  show: false
              },
              type: 'area'
          },
          dataLabels: {
              enabled: false
          },
          stroke: {
              width: 2,
              curve: 'smooth'
          },
          legend: {
            show: true,
            horizontalAlign: 'left',
            position: 'top',
            markers: {
              height: 8,
              width: 8,
              radius: 12,
              offsetX: -3
            },
            labels: {
              colors: axisColor
            },
            itemMargin: {
              horizontal: 10
            }
          },
          markers: {
              size: 6,
              colors: 'transparent',
              strokeColors: 'transparent',
              strokeWidth: 4,
              discrete: [
              //   {
              //     fillColor: config.colors.white,
              //     seriesIndex: 0,
              //     dataPointIndex: 7,
              //     strokeColor: config.colors.primary,
              //     strokeWidth: 2,
              //     size: 6,
              //     radius: 8
              //   }
              ],
              hover: {
                  size: 7
              }
          },
          fill: {
              type: 'gradient',
              gradient: {
                  shade: shadeColor,
                  shadeIntensity: 0.6,
                  opacityFrom: 0.5,
                  opacityTo: 0.25,
                  stops: [0, 95, 100]
              }
          },
          grid: {
              borderColor: borderColor,
              strokeDashArray: 3,
              padding: {
                  top: -20,
                  bottom: -8,
                  left: 0,
                  right: 0
              }
          },
          xaxis: {
              categories: [],
              axisBorder: {
                  show: false
              },
              axisTicks: {
                  show: false
              },
              labels: {
                  show: true,
                  style: {
                  fontSize: '13px',
                  colors: axisColor
                  }
              }
          },
          yaxis: {
              labels: {
                  show: false,
                  formatter: function (val) {
                    return val.toFixed(2) + " кг"
                }
              },
              min: 0,
              max: 50,
              tickAmount: 4
          }
      });

      let [averageConfig, setAverageConfig] = useState({
        series: [],
        options: {
            chart: {
                width: 60,
                height: 60,
                type: 'radialBar'
            },
            plotOptions: {
                radialBar: {
                startAngle: 0,
                endAngle: 360,
                strokeWidth: '8',
                hollow: {
                    margin: 2,
                    size: '45%'
                },
                track: {
                    strokeWidth: '50%',
                    background: borderColor
                },
                dataLabels: {
                    show: true,
                    name: {
                    show: false
                    },
                    value: {
                        formatter: (val) => ( val * (maxValueCowYield - minValueCowYield) ) / 100 + minValueCowYield,
                    offsetY: 5,
                    color: '#697a8d',
                    fontSize: '13px',
                    show: true
                    }
                }
                }
            },
            fill: {
                type: 'solid',
                colors: config.colors.primary
            },
            stroke: {
                lineCap: 'round'
            },
            grid: {
                padding: {
                top: -10,
                bottom: -15,
                left: -10,
                right: -10
                }
            },
            states: {
                hover: {
                filter: {
                    type: 'none'
                }
                },
                active: {
                filter: {
                    type: 'none'
                }
                }
            }     
        }  
      });

    let [deviation, setDeviation] = useState(0);

    let [bestFarm, setBestFarm] = useState({
        bestFarmName: "",
        bestFarmValue: 0,
        bestFarmDeviation: 0,
    });

    let [worstFarm, setWorstFarm] = useState({
        worstFarmName: "",
        worstFarmValue: 0,
        worstFarmDeviation: 0,
    });

    useEffect(() => { 
        let cowYieldsData = null;
        let cowYieldsDataCurrent = null;

        let seriesArray = [];

        let average = 0;
        let count = 0;

        let averageCurrent = 0;
        let countCurrent = 0;

        let maxFarmName = null;
        let minFarmName = null;

        let maxValue = -1;
        let minValue = 99999;

        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => {
                cowYieldsData = json.productivityYieldByCowForecast;
                cowYieldsDataCurrent = json.productivityYieldByCow;
            })
            .then(() => {
                if (farm.farmVisibility) {
                    let currentSerie = {
                        name: null,
                        data: null
                    }; 
                    
                    currentSerie.name = farm.farmName;

                    cowYieldsData = cowYieldsData.slice(0, 3);
    
                    currentSerie.data = cowYieldsData;

                    if (cowYieldsData[0] != undefined) {
                        if (parseFloat(cowYieldsData[0]) > maxValue) {
                            maxValue = parseFloat(cowYieldsData[0]); 
                            maxFarmName = farm.farmName;
                        }

                        if (parseFloat(cowYieldsData[0]) < minValue) {
                            minValue = parseFloat(cowYieldsData[0]); 
                            minFarmName = farm.farmName;
                        }

                        average = average + parseFloat(cowYieldsData[0]);
        
                        count = count + 1;
                    }

                    if (cowYieldsDataCurrent[cowYieldsDataCurrent.length - 1] != undefined) {
                        averageCurrent = averageCurrent + parseFloat(cowYieldsDataCurrent[cowYieldsDataCurrent.length - 1]);
        
                        countCurrent = countCurrent + 1;
                    }

                    seriesArray.push(currentSerie);    
                }            
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {
            setSeries(seriesArray);

            if (countCurrent != 0) {
                averageCurrent = averageCurrent / countCurrent;
            }
           
            if (count != 0) {
                average = average / count;
            }

            const login = localStorage.getItem("login");
            const password = localStorage.getItem("password");

            let manager = null;

            postData("https://api.maystar.polyefir-mobile.by/loginManager", { login: login, password: password })
            .then((response) => manager = response)
            .then(() => {
                maxValueCowYield = parseFloat(manager.cowYieldGoal);
                valueToPercentCowYield = (val) => ( (val - minValueCowYield) * 100 ) / (maxValueCowYield - minValueCowYield);

                averageConfig = {
                    ...averageConfig,
                    series: [valueToPercentCowYield(average.toFixed(2))],
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        formatter: (val) => ( val * (maxValueCowYield - minValueCowYield) ) / 100 + minValueCowYield
                                    }
                                }
                            }
                        }
                    }
                };
                
                setAverageConfig(averageConfig);
            })
            .catch((error) => console.log(error));
        
            if (averageCurrent != 0) {
                setDeviation(((average - averageCurrent) / averageCurrent) * 100);   
            }

            if (props.farms.length == 1) {
                let maxDeviation = 0;

                if (average != 0) {
                    maxDeviation = ((maxValue - average) / average) * 100;   
                }

                bestFarm = {
                    bestFarmName: maxFarmName,
                    bestFarmValue: maxValue,
                    bestFarmDeviation: maxDeviation,
                };
                
                setBestFarm(bestFarm);

                worstFarm = {
                    worstFarmName: "-",
                    worstFarmValue: 0,
                    worstFarmDeviation: 0,
                };
                
                setWorstFarm(worstFarm);
            } else if (props.farms.length > 1) {
                let maxDeviation = 0;

                if (average != 0) {
                    maxDeviation = ((maxValue - average) / average) * 100;   
                }

                bestFarm = {
                    bestFarmName: maxFarmName,
                    bestFarmValue: maxValue,
                    bestFarmDeviation: maxDeviation,
                };
                
                setBestFarm(bestFarm);

                let minDeviation = 0;

                if (average != 0) {
                    minDeviation = ((minValue - average) / average) * 100;   
                }

                worstFarm = {
                    worstFarmName: minFarmName,
                    worstFarmValue: minValue,
                    worstFarmDeviation: minDeviation,
                };
                
                setWorstFarm(worstFarm);
            }
          })
        
        const categoriesArray = [];   
 
        for (let i = 1; i < 4; i++) {
            if (currentDate.getMonth() + i < 12) {
                categoriesArray.push(months[currentDate.getMonth() + i]);  
            } else {
                categoriesArray.push(months[currentDate.getMonth() + i - 12]);  
            }
        } 

        options = {
            ...options,
            xaxis: { 
              categories: categoriesArray
            }
        };

        setOptions(options)
    }, [props.farms]);

    return (
        <div class="tab-pane fade show active" id="cowYieldsMonthlyForecastTab" role="tabpanel">
            <div class="d-flex p-4 pt-3 justify-content-between">
                <div class="d-flex">
                    <div class="avatar flex-shrink-0 me-3">
                        <i class="fa-solid fa-thumbs-up icon-best"></i>         
                    </div>
                    <div>
                        <small class="text-muted d-block">{bestFarm.bestFarmName}</small>
                        <div class="d-flex align-items-center">
                            <h6 class="mb-0 me-1">{bestFarm.bestFarmValue.toFixed(2)}</h6>
                            <small class="text-success fw-semibold">
                            <i class="bx bx-chevron-up"></i>
                            {Math.abs(bestFarm.bestFarmDeviation).toFixed(2)}%
                            </small>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="avatar flex-shrink-0 me-3">
                        <i class="fa-solid fa-thumbs-down icon-worst"></i>
                    </div>
                    <div>
                        <small class="text-muted d-block">{worstFarm.worstFarmName}</small>
                        <div class="d-flex align-items-center">
                            <h6 class="mb-0 me-1">{worstFarm.worstFarmValue.toFixed(2)}</h6>
                            <small class="text-danger fw-semibold">
                            <i class="bx bx-chevron-down"></i>
                            {Math.abs(worstFarm.worstFarmDeviation).toFixed(2)}%
                            </small>
                        </div>
                    </div>
                </div>
                <div class="d-flex">                
                </div>
            </div>
            <Chart options={options} series={series} type="area" height={215} />
            <div class="d-flex justify-content-center pt-4 gap-2">
                <div class="flex-shrink-0">
                <Chart options={averageConfig.options} series={averageConfig.series} type="radialBar" width={60} height={60} />
                </div>
                <div>
                <p class="mb-n1 mt-1 fw-semibold-2">Суточный удой на корову</p>
                {(deviation > 0) && (
                    <small class="text-muted fw-semibold-2">на {Math.abs(deviation).toFixed(2)}% рост в следующем месяце</small>
                )}
                {(deviation < 0) && (
                    <small class="text-muted fw-semibold-2">на {Math.abs(deviation).toFixed(2)}% снижение в следующем месяце</small>
                )}
                {(deviation == 0) && (
                    <small class="text-muted fw-semibold-2">не изменится в течении следующего месяца</small>
                )}
                </div>
            </div>
        </div>
    );
  }