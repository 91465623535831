import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import React, { Component } from 'react';
import Home from './Home';  
import LoginContainer from './Login';  
import Reproduction from './Reproduction';
import Health from './Health';   
import Milk from './Milk';  
import Tech from './Tech'; 
import Goals from './Goals'; 
import Forecasts from './Forecasts'; 
import NotFoundRoute from './NotFoundRoute';  
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect } from "react";
import { useState } from "react";

const THEME = createTheme({
  typography: {
   "fontFamily": `"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
  }
});

const App = props => {   
  const [selectedFarmsData, setSelectedFarmsData] = useState(() => {
    const selectedFarms = localStorage.getItem('selectedFarms');
    return JSON.parse(selectedFarms) ?? [];
  });
  
  useEffect(() => {
    localStorage.setItem('selectedFarms', JSON.stringify(selectedFarmsData));
  }, [selectedFarmsData]);

  const [farmsRatingsData, setFarmsRatingsData] = useState(() => {
    const farmsRatings = localStorage.getItem('farmsRatings');
    return JSON.parse(farmsRatings) ?? [];
  });
  
  useEffect(() => {
    localStorage.setItem('farmsRatings', JSON.stringify(farmsRatingsData));
  }, [farmsRatingsData]);   
 
  return (
    <ThemeProvider theme={THEME}>
      <Router>  
        <Routes>  
            <Route exact path='/' element={< Home selectedFarmsData={selectedFarmsData} setSelectedFarmsData={setSelectedFarmsData} farmsRatingsData={farmsRatingsData} setFarmsRatingsData={setFarmsRatingsData} />}></Route>  
            <Route exact path='/login' element={< LoginContainer />}></Route>  
            <Route exact path='/reproduction' element={< Reproduction selectedFarmsData={selectedFarmsData} setSelectedFarmsData={setSelectedFarmsData} />}></Route>
            <Route exact path='/health' element={< Health selectedFarmsData={selectedFarmsData} setSelectedFarmsData={setSelectedFarmsData} />}></Route>    
            <Route exact path='/milk' element={< Milk selectedFarmsData={selectedFarmsData} setSelectedFarmsData={setSelectedFarmsData} />}></Route>
            <Route exact path='/tech' element={< Tech selectedFarmsData={selectedFarmsData} setSelectedFarmsData={setSelectedFarmsData} />}></Route>
            <Route exact path='/goals' element={< Goals selectedFarmsData={selectedFarmsData} setSelectedFarmsData={setSelectedFarmsData} />}></Route>
            <Route exact path='/forecasts' element={< Forecasts selectedFarmsData={selectedFarmsData} setSelectedFarmsData={setSelectedFarmsData} />}></Route>
            <Route exact path="*" element={< NotFoundRoute />}></Route>
          </Routes>    
      </Router> 
    </ThemeProvider> 
  );
}

export default App;
