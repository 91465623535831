import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1',
      calf: '#808080',
      openHeifer: '#8381ff',
      insemHeifer: '#90ff00',
      uncalved: '#57ffff',
      involution: '#f9b9a1',
      open: '#7e0cff',
      insem: '#25803d',
      incalf: '#3ba7ff',
      barren: '#f5009d',
      dryoff: '#a28200',
      cull: '#f9bf00'
    }
};

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

let maxValueMastitis = 3;
let minValueMastitis = 10;
let valueToPercentMastitis = (val) => ( (val - minValueMastitis) * 100 ) / (maxValueMastitis - minValueMastitis);

let maxValueLameness = 5;
let minValueLameness = 10;
let valueToPercentLameness = (val) => ( (val - minValueLameness) * 100 ) / (maxValueLameness - minValueLameness);

export default function HealthRating(props) {
    async function postData(url = '', data = {}) {
      const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(data) 
      });
      return response.json(); 
    }

    const [minRating, setMinRating] = useState(0);

    const [maxRating, setMaxRating] = useState(0);

    const [minRatingFarm, setMinRatingFarm] = useState("");

    const [maxRatingFarm, setMaxRatingFarm] = useState("");

    useEffect(() => { 
      let reportListData = null;
      let healthColumnsData = null;
      let healthRowsData = null;
      let statusesData = null;

      let reportsIds = [];
      let farmsArray = [];

      let sickArrayMastitis = [];
      let sickArrayLameness = [];
      let totalArray = [];

      let sickCountMastitis = 0;
      let sickCountLameness = 0;
      let totalCount = 0; 

      let value = 0;

      let maxRating = -1;
      let minRating = 9999;

      let maxRatingFarm = "";
      let minRatingFarm = "";

      const login = localStorage.getItem("login");
      const password = localStorage.getItem("password");

      let manager = null;

      postData("https://api.maystar.polyefir-mobile.by/loginManager", { login: login, password: password })
      .then((response) => manager = response)
      .then(() => {
        maxValueMastitis = parseFloat(manager.mastitisGoal);
        valueToPercentMastitis = (val) => ( (val - minValueMastitis) * 100 ) / (maxValueMastitis - minValueMastitis);

        maxValueLameness = parseFloat(manager.lamenessGoal);
        valueToPercentLameness = (val) => ( (val - minValueLameness) * 100 ) / (maxValueLameness - minValueLameness);

        Promise.all(props.farms.map(farm =>
          fetch("https://api.maystar.polyefir-mobile.by/getReportList/" + farm.farmId)
          .then((response) => response.json())
          .then((json) => reportListData = json.reportList)
          .then(() => {
            if (farm.farmVisibility) {
              for (let i = 0; i < reportListData.length; i++){
                if (reportListData[i].name == "Текущие лечения") {
                  const value = {
                    farmId: farm.farmId,
                    reportId: reportListData[i].reportId
                  }

                  reportsIds.push(value);

                  farmsArray.push(farm.farmName);

                  break;
                }   
              }
            }            
          })
          .catch((error) => console.log(error))
        ))
        .then(() => {
          Promise.all(reportsIds.map(report =>
            fetch("https://api.maystar.polyefir-mobile.by/getReportByFarm/" + report.farmId + "/" + report.reportId)
            .then((response) => response.json())
            .then((json) => {
              healthColumnsData = json.reportColumns;
              healthRowsData = json.reportRows;
            })
            .then(() => {
              let diseaseIndex = healthColumnsData.indexOf("Заболевание"); 

              sickCountMastitis = 0;
              sickCountLameness = 0;

              healthRowsData.forEach(element => {
                if ((element.reportRowValues[diseaseIndex] == "Кровавое молоко") || (element.reportRowValues[diseaseIndex] == "Клинический мастит") || (element.reportRowValues[diseaseIndex] == "Отек вымени") ||
                          (element.reportRowValues[diseaseIndex] == "Рана соска") || (element.reportRowValues[diseaseIndex] == "Мастит 2")) {
                            sickCountMastitis = sickCountMastitis + 1;
                } 

                if ((element.reportRowValues[diseaseIndex] == "Артрит") || (element.reportRowValues[diseaseIndex] == "Вывих") || (element.reportRowValues[diseaseIndex] == "Ламинит") ||
                      (element.reportRowValues[diseaseIndex] == "Перелом") || (element.reportRowValues[diseaseIndex] == "Повреждения копыта") || (element.reportRowValues[diseaseIndex] == "Тенденит")) {
                        sickCountLameness = sickCountLameness + 1;
                } 
              });

              sickArrayMastitis.push(sickCountMastitis);
              sickArrayLameness.push(sickCountLameness);
            })
            .catch((error) => console.log(error))
          )) 
          .then(() => {
            Promise.all(reportsIds.map(report =>
              fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + report.farmId)
              .then((response) => response.json())
              .then((json) => statusesData = json.statusValues)
              .then(() => {
                totalCount = 0;

                statusesData.forEach((element, index) => {
                  totalCount = totalCount + parseInt(element.replace(/ /g,''));                    
                });    

                totalArray.push(totalCount);
              })
              .catch((error) => console.log(error))
            )) 
            .then(() => {
              totalArray.forEach((element, index) => {
                if (element != 0) {
                  value = valueToPercentMastitis(sickArrayMastitis[index] / element * 100);

                  value = value + valueToPercentLameness(sickArrayLameness[index] / element * 100);

                  if (value < minRating) {
                    minRating = value;
                    minRatingFarm = farmsArray[index];
                  }

                  if (value > maxRating) {
                    maxRating = value;
                    maxRatingFarm = farmsArray[index];
                  }
                }                    
              });

              setMinRating(minRating);
              setMaxRating(maxRating);

              setMinRatingFarm(minRatingFarm);
              setMaxRatingFarm(maxRatingFarm);  
            })         
          })          
        })
      })
      .catch((error) => console.log(error));  
    }, [props.farms]);

    return (
      <div class="col-lg-4 col-md-4 order-1">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-6 mb-4">
            <div class="card">
                <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                    <div class="avatar flex-shrink-0">
                        <i class="fa-solid fa-thumbs-up icon-best"></i>
                    </div>
                </div>
                <span class="fw-semibold">{maxRatingFarm}</span>
                <h3 class="card-title mb-1">{maxRating.toFixed(0)}</h3>
                <small class="text-success fw-bold">Лучший рейтинг</small>
                </div>
            </div>
            </div>
            <div class="col-lg-6 col-md-12 col-6 mb-4">
            <div class="card">
                <div class="card-body">
                <div class="card-title d-flex align-items-start justify-content-between">
                    <div class="avatar flex-shrink-0">
                        <i class="fa-solid fa-thumbs-down icon-worst"></i>
                    </div>
                </div>
                <span class="fw-semibold">{minRatingFarm}</span>
                <h3 class="card-title text-nowrap mb-1">{minRating.toFixed(0)}</h3>
                <small class="text-danger fw-bold">Худший рейтинг</small>
                </div>
            </div>
            </div>
        </div>
      </div>      
    );
  }