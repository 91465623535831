import Chart from "react-apexcharts";
import { useEffect } from "react";
import { useState } from "react";

let config = {
    colors: {
      primary: '#696cff',
      secondary: '#8592a3',
      success: '#71dd37',
      info: '#03c3ec',
      warning: '#ffab00',
      danger: '#ff3e1d',
      dark: '#233446',
      black: '#000',
      white: '#fff',
      body: '#f4f5fb',
      headingColor: '#566a7f',
      axisColor: '#a1acb8',
      borderColor: '#eceef1'
    }
};

const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"];

const currentDate = new Date();

let cardColor, headingColor, axisColor, shadeColor, borderColor;

cardColor = config.colors.white;
headingColor = config.colors.headingColor;
axisColor = config.colors.axisColor;
borderColor = config.colors.borderColor;

export default function MilkCowsChart(props) {
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({
        chart: {
            height: 300,
            stacked: false,
            type: 'bar',
            toolbar: { show: false }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '33%',
              borderRadius: 20,
              startingShape: 'rounded',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 1,
            lineCap: 'round',
            colors: [cardColor]
          },
          legend: {
            show: true,
            horizontalAlign: 'left',
            position: 'top',
            markers: {
              height: 8,
              width: 8,
              radius: 12,
              offsetX: -3
            },
            labels: {
              colors: axisColor
            },
            itemMargin: {
              horizontal: 10
            }
          },
          grid: {
            borderColor: borderColor,
            padding: {
              top: 0,
              bottom: -8,
              left: 20,
              right: 20
            }
          },
          tooltip: {
            shared: true,
            intersect: false
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: '13px',
                colors: axisColor
              }
            },
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false
            }
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '13px',
                colors: axisColor
              }
            }
          },
          responsive: [
            {
              breakpoint: 1700,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '32%'
                  }
                }
              }
            },
            {
              breakpoint: 1580,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '35%'
                  }
                }
              }
            },
            {
              breakpoint: 1440,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '42%'
                  }
                }
              }
            },
            {
              breakpoint: 1300,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '48%'
                  }
                }
              }
            },
            {
              breakpoint: 1200,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '40%'
                  }
                }
              }
            },
            {
              breakpoint: 1040,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 11,
                    columnWidth: '48%'
                  }
                }
              }
            },
            {
              breakpoint: 991,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '30%'
                  }
                }
              }
            },
            {
              breakpoint: 840,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '35%'
                  }
                }
              }
            },
            {
              breakpoint: 768,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '28%'
                  }
                }
              }
            },
            {
              breakpoint: 640,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '32%'
                  }
                }
              }
            },
            {
              breakpoint: 576,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '37%'
                  }
                }
              }
            },
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '45%'
                  }
                }
              }
            },
            {
              breakpoint: 420,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '52%'
                  }
                }
              }
            },
            {
              breakpoint: 380,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    columnWidth: '60%'
                  }
                }
              }
            }
          ],
          states: {
            hover: {
              filter: {
                type: 'none'
              }
            },
            active: {
              filter: {
                type: 'none'
              }
            }
          }
    });  

    useEffect(() => { 
        let milkCowsEmptyData = null;
        let milkCowsData = null;

        let seriesArray = [];

        Promise.all(props.farms.map(farm =>
            fetch("https://api.maystar.polyefir-mobile.by/getStatistics/" + farm.farmId)
            .then((response) => response.json())
            .then((json) => {
              milkCowsEmptyData = json.milkingCowEmpty;
              milkCowsData = json.milkingCowValue;
            })
            .then(() => {
                if (farm.farmVisibility) {
                    let currentSerie = {
                        name: null,
                        data: []
                    }; 

                    currentSerie.name = farm.farmName;

                    milkCowsEmptyData = milkCowsEmptyData.slice(6);
                    milkCowsData = milkCowsData.slice(6);

                    let result = milkCowsData.map(function (value, index) {
                      if (index > 0) {
                        if (parseInt(milkCowsData[index - 1]) + parseInt(milkCowsEmptyData[index - 1]) > parseInt(milkCowsData[index]) + parseInt(milkCowsEmptyData[index])) {
                          return parseInt(milkCowsEmptyData[index]);       
                        } else if (parseInt(milkCowsData[index - 1]) + parseInt(milkCowsEmptyData[index - 1]) < parseInt(milkCowsData[index]) + parseInt(milkCowsEmptyData[index])) {
                          return parseInt(value) + parseInt(milkCowsEmptyData[index]);
                        } else if (parseInt(milkCowsData[index - 1]) + parseInt(milkCowsEmptyData[index - 1]) == parseInt(milkCowsData[index]) + parseInt(milkCowsEmptyData[index])) {
                          return parseInt(milkCowsEmptyData[index]);       
                        }        
                      }
                    });

                    result = result.slice(1);

                    currentSerie.data = result;

                    seriesArray.push(currentSerie);    
                }            
            })
            .catch((error) => console.log(error))
          ))
          .then(() => {
            setSeries(seriesArray);

            options = {
              ...options,
              plotOptions: {
                bar: {
                  borderRadius: 20 / seriesArray.length,
                }
              },
            };
  
            setOptions(options)
          })
        
        const categoriesArray = [];  
 
        for (let i = 1; i < 7; i++) {
          if (currentDate.getMonth() + i < 12) {
              categoriesArray.push(months[currentDate.getMonth() + i]);  
          } else {
              categoriesArray.push(months[currentDate.getMonth() + i - 12]);  
          }
      }  

        options = {
            ...options,
            xaxis: { 
              categories: categoriesArray
            }
        };

        setOptions(options)
    }, [props.farms]);

    return (
        <Chart options={options} series={series} type="bar" width={680} height={400} />
    );
  }